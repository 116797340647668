import React, { useState, useEffect } from "react";
import { Track } from "livekit-client";
import "./index.css";
import { ParticipantTile, PreJoin } from "@livekit/components-react";
import "@livekit/components-styles";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { Blocks } from "react-loader-spinner";
import { MDBRow, MDBCol } from "mdbreact";
import { useAuth } from "../Context/AuthContext";
import { firebase } from "../../../firebase/config";

const PrepareCall = () => {
  const [roomName, setRoomName] = useState("testing");
  const [videoPlayerToken, setVideoPlayerToken] = useState("");
  const [videoPublisherToken, setVideoPublisherToken] = useState("");
  const [audioPlayerToken, setAudioPlayerToken] = useState("");
  const [audioPublisherToken, setAudioPublisherToken] = useState("");
  const [statement, setStatement] = useState("Finding an available machine...");
  const [loadingStatus, setLoadingStatus] = useState("Queued");
  const [roomID, setRoomID] = useState("");
  const [cloneID, setCloneID] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [voiceType, setVoiceType] = useState("");
  const [roomCreated, setRoomCreated] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const history = useHistory();
  const { workspaceID } = useAuth();

  const [loadingSession, setLoadingSession] = useState(null);
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    setRoomID(query.get("roomID"));
    setCloneID(query.get("cloneID"));
    setVoiceID(query.get("voiceID"));
    setVoiceType(query.get("voiceType"));
  }, []);

  useEffect(() => {
    if (roomID && roomCreated) {
      firebase
        .firestore()
        .collection("roomQueue")
        .doc(roomID)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            history.push("/");
          }
        });
    }
  }, [roomID, roomCreated]);

  useEffect(() => {
    const startSession = async () => {
      try {
        const uid = firebase.auth().currentUser.uid;
        const createSession = firebase
          .functions()
          .httpsCallable("createSession");

        // Await the function call
        const result = await createSession({
          workspaceID,
          uid,
          cloneID,
          voiceID,
          roomID,
          voiceType,
        });
        console.log(result.data);

        // Process result data
        if (result.data.status === "Success") {
          if (result.data.sessionStatus === "Queued") {
            setLoadingSession(true);
            setStatement("Connecting to machine...");
            setLoadingStatus("Queued");
            setRoomCreated(true);
          } else if (result.data.sessionStatus === "Finished") {
            setLoadingSession(true);
            setStatement("Session has ended!");
            setLoadingStatus("Finished");
            setRoomCreated(true);
          } else if (result.data.sessionStatus === "Active") {
            setLoadingSession(true);
            setStatement("Join session");
            setLoadingStatus("Active");
            setRoomCreated(true);
            setLoadingSession(true);
          } else if (result.data.sessionStatus === "Out of Credits") {
            setStatement("Out of Credits");
            setLoadingStatus("Out of Credits");
            setLoadingSession(true);
          }
        }
      } catch (error) {
        console.error("Error creating room:", error);
      }
    };

    // Ensure all required data is available before calling startSession
    if (
      firebase.auth().currentUser &&
      workspaceID &&
      cloneID &&
      voiceID &&
      roomID
    ) {
      startSession();
    }
  }, [firebase.auth().currentUser, cloneID, voiceID, roomID, workspaceID]);

  useEffect(() => {
    if (roomCreated && roomID && !updatingStatus) {
      firebase
        .firestore()
        .collection("roomQueue")
        .doc(roomID)
        .onSnapshot((doc) => {
          if (doc.exists && doc.data() && !updatingStatus) {
            if (doc.data().status === "Queued") {
              setStatement("Connecting to machine...");
              setLoadingStatus("Queued");
              setRoomCreated(true);
              setLoadingSession(true);
            } else if (doc.data().status === "Finished") {
              setStatement("Session has ended!");
              setLoadingStatus("Finished");
              setRoomCreated(true);
              setLoadingSession(true);
            } else if (doc.data().status === "Active") {
              setStatement("Join session");
              setLoadingStatus("Active");
              setRoomCreated(true);
              setVideoPlayerToken(doc.data().videoPlayerAccessToken);
              setVideoPublisherToken(doc.data().videoPublisherAccessToken);
              setAudioPlayerToken(doc.data().audioPlayerAccessToken);
              setAudioPublisherToken(doc.data().audioPublisherAccessToken);
              setLoadingSession(false);
            }
          } else {
            // Handle the case when the document is deleted or does not exist
            setStatement("Session has ended!");
            setLoadingStatus("Finished");
            setLoadingSession(true);
            setRoomCreated(true);
          }
        });
    }
  }, [roomID, roomCreated, updatingStatus]);

  if (loadingSession === null) {
    return <div style={{ height: "100vh", backgroundColor: "#111111" }}></div>;
  }

  return loadingSession ? (
    <div style={{ height: "100vh", backgroundColor: "#111111" }}>
      {loadingStatus === "Queued" ? (
        <MDBRow>
          <MDBCol size="12" className="d-flex justify-content-center">
            <Blocks
              width={300}
              height={300}
              color="#fff"
              wrapperStyle={{
                display: "inline-block",
                marginTop: 160,
                fontSize: 60,
              }}
              wrapperClass="blocks-wrapper"
              ariaLabel="blocks-loading"
              visible={true}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p style={{ color: "white", fontSize: 40, marginTop: 30 }}>
              {statement}
            </p>
          </MDBCol>
        </MDBRow>
      ) : loadingStatus === "Active" ? (
        <MDBRow>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontSize: 40,
                marginTop: 150,
                fontFamily: "SSBold",
              }}
            >
              Rejoin room
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontSize: 24,
                marginTop: -9,
                fontFamily: "SSRegular",
                width: "80%",
                textAlign: "center",
              }}
            >
              Rejoin to continue using this clone. Or end the session now.
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div style={{ display: "flex", marginTop: -3 }}>
              <div
                style={{
                  height: 50,
                  width: 150,
                  marginTop: 26,
                  display: "inline-block",
                  marginRight: 10,
                  border: "1px solid white",
                  borderRadius: 5,
                  textAlign: "center",
                  color: "white",
                  fontSize: 19,
                  fontFamily: "SSRegular",
                  paddingTop: 10,
                }}
              >
                <p>Rejoin</p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 280,
                  backgroundColor: "#1f8cf9",
                  marginTop: 26,
                  display: "inline-block",
                  marginLeft: 10,
                  borderRadius: 5,
                  textAlign: "center",
                  color: "white",
                  fontSize: 19,
                  fontFamily: "SSRegular",
                  paddingTop: 10,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  setUpdatingStatus(true);
                  await firebase
                    .firestore()
                    .collection("roomQueue")
                    .doc(roomID)
                    .update({
                      status: "Finished",
                    })
                    .then(() => {
                      history.push("/");
                    });
                }}
              >
                <p>Return to home screen</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      ) : loadingStatus === "Out of Credits" ? (
        <MDBRow>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontSize: 40,
                marginTop: 150,
                fontFamily: "SSBold",
              }}
            >
              Out of Credits
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontSize: 24,
                marginTop: -9,
                fontFamily: "SSRegular",
                width: "80%",
                textAlign: "center",
              }}
            >
              Purchase more credits in order to start a session
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div style={{ display: "flex", marginTop: -3 }}>
              <div
                style={{
                  height: 50,
                  width: 280,
                  backgroundColor: "#1f8cf9",
                  marginTop: 26,
                  display: "inline-block",
                  marginLeft: 10,
                  borderRadius: 5,
                  textAlign: "center",
                  color: "white",
                  fontSize: 19,
                  fontFamily: "SSRegular",
                  paddingTop: 10,
                  cursor: "pointer",
                }}
                onClick={() => history.push("/upgrade")}
              >
                <p>Upgrade your plan</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      ) : (
        <MDBRow>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontSize: 40,
                marginTop: 150,
                fontFamily: "SSBold",
              }}
            >
              Session has ended
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontSize: 24,
                marginTop: -9,
                fontFamily: "SSRegular",
                width: "80%",
                textAlign: "center",
              }}
            >
              Return to home to start a new session.
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div style={{ display: "flex", marginTop: -3 }}>
              <div
                style={{
                  height: 50,
                  width: 280,
                  backgroundColor: "#1f8cf9",
                  marginTop: 26,
                  display: "inline-block",
                  marginLeft: 10,
                  borderRadius: 5,
                  textAlign: "center",
                  color: "white",
                  fontSize: 19,
                  fontFamily: "SSRegular",
                  paddingTop: 10,
                  cursor: "pointer",
                }}
                onClick={() => history.push("/")}
              >
                <p>Return to home screen</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      )}
    </div>
  ) : (
    <div
      style={{ scroll: "hidden", height: "100vh", backgroundColor: "#111111" }}
      data-lk-theme="default"
    >
      <div className="d-flex justify-content-center">
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "white",
              fontSize: 45,
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 40,
            }}
          >
            Joining Mimzy Call...
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "white",
              fontSize: 25,
              textAlign: "center",
              marginTop: 0,
              width: 500,
            }}
          >
            Make sure to keep your face neutral and mouth closed until the call
            starts. And sit in the center of the screen before joining.
          </p>
        </div>
      </div>
      <PreJoin
        onSubmit={() =>
          history.push(
            `/call?roomID=${roomID}&videoPlayerToken=${videoPlayerToken}&videoPublisherToken=${videoPublisherToken}&audioPlayerToken=${audioPlayerToken}&audioPublisherToken=${audioPublisherToken}`
          )
        }
        onValidate={() =>
          roomID &&
          videoPublisherToken &&
          videoPlayerToken &&
          audioPlayerToken &&
          audioPublisherToken
        }
        joinLabel="Start Call"
        style={{
          "#username": {
            display: "none",
          },
        }}
      />
    </div>
  );
};

export default PrepareCall;
