// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { firebase } from "../../../firebase/config";
import mixpanel, { init } from "mixpanel-browser";
import axios from "axios"; // Ensure axios is imported

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState(0);
  const [paid, setPaid] = useState(null);
  const [currency, setCurrency] = useState("usd"); // Default currency
  const [price, setPrice] = useState(null); // Initialize price state
  const [onboarded, setOnboarded] = useState(null);
  const [workspaceID, setWorkSpaceID] = useState("");
  const [members, setMembers] = useState([]);
  const [active, setActive] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [monthlyHours, setMonthlyHours] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      const email = firebase.auth().currentUser.email;

      mixpanel.identify(uid);
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          if (doc.data()) {
            setWorkSpaceID(doc.data().activeWorkSpace);
            if (doc.data().onboarded) {
              setOnboarded(doc.data().onboarded);
            } else {
              setOnboarded(false);
            } // Convert Firestore Timestamp to Date
          }
        });
    }
  }, [firebase.auth().currentUser]);

  // Set up the workspace listener to count interview and full-time clones used
  useEffect(() => {
    if (workspaceID) {
      // Listen for changes in the workspace document
      firebase
        .firestore()
        .collection("workspace")
        .doc(workspaceID)
        .onSnapshot((doc) => {
          if (doc.data()) {
            setActive(doc.data().active);
            setMembers(doc.data().members);
            setMonthlyHours(doc.data().monthlyHours);
            setTimeLeft(doc.data().timeLeft);
            setPaid(doc.data().paid);
          }
        });
    }
  }, [workspaceID]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setLoggedIn(!!user);
      setLoading(false);
    });

    return unsubscribe; // Unsubscribe on unmount
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        loading,
        credits,
        paid,
        currency,
        price,
        onboarded,
        setOnboarded,
        workspaceID,
        members,
        active,
        currentPlan,
        monthlyHours,
        timeLeft,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
