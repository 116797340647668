import React, { Component, useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { firebase } from "../../firebase/config";
import ScrollToTop from "./ScrollToTop";
import { withRouter } from "react-router-dom";
import Landing from "../Parts/Landing/Landing";
import LandingMobile from "../Parts/Landing/LandingMobile";
import Call from "../Parts/Call/Call";
import Stream from "../Parts/Stream/Stream";
import PrepareCall from "../Parts/PrepareCall/PrepareCall";
import InstallationGuide from "../Parts/InstallationGuide/InstallationGuide";
import VoiceClone from "../Parts/VoiceClone/VoiceClone";
import "./index.css";
import Privacy from "../Parts/Privacy/Privacy";
import Terms from "../Parts/Terms/Terms";
import { useAuth } from "../Parts/Context/AuthContext";
import { Analytics } from "@vercel/analytics/react";
import PricingPage from "../Parts/PricingPage/PricingPage";
import FAQ from "../Parts/FAQ";
import Dashboard from "../Parts/Dashboard/Dashboard";
import SignUp from "../Parts/SignUp/SignUp";
import Upgrade from "../Parts/Upgrade/UpgradeFinal";
import PricingPageMobile from "../Parts/PricingPage/PricingPageMobile";
import FAQMobile from "../Parts/FAQMobile";
import PrivacyMobile from "../Parts/Privacy/PrivacyMobile";
import TermsMobile from "../Parts/Terms/TermsMobile";

const BasePage = () => {
  const [isDesktop, setIsDesktop] = useState(0);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { loggedIn, loading } = useAuth();

  useEffect(() => {
    screenSizeDetector();
    window.addEventListener("resize", screenSizeDetector);
  }, []);

  // useEffect(() => {
  //   firebase.auth().signOut();
  // }, []);

  const screenSizeDetector = () => {
    if (window.innerWidth > 900 && window.innerWidth < 1000) {
      setIsDesktop(true);
    } else if (window.innerWidth <= 900) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  };

  if (loading === true || loggedIn === null) {
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <Analytics />

      <Switch>
        {!firebase.auth().currentUser ? (
          <Route
            exact
            path="/"
            render={() => (isDesktop ? <Landing /> : <LandingMobile />)}
          />
        ) : (
          <Route
            exact
            path="/"
            render={() => (isDesktop ? <Dashboard /> : <Dashboard />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/call"
            render={() => (isDesktop ? <Call /> : <Call />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/prepare-call"
            render={() => (isDesktop ? <PrepareCall /> : <PrepareCall />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/upgrade"
            render={() => (isDesktop ? <Upgrade /> : <Upgrade />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/guide"
            render={() =>
              isDesktop ? <InstallationGuide /> : <InstallationGuide />
            }
          />
        )}

        <Route
          exact
          path="/stream"
          render={() => (isDesktop ? <Stream /> : <Stream />)}
        />

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/voice-clone"
            render={() => (isDesktop ? <VoiceClone /> : <VoiceClone />)}
          />
        )}

        <Route
          exact
          path="/pricing"
          render={() =>
            isDesktop ? (
              <PricingPage
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            ) : (
              <PricingPageMobile
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            )
          }
        />

        <Route
          exact
          path="/faq"
          render={() =>
            isDesktop ? (
              <FAQ
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            ) : (
              <FAQMobile
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            )
          }
        />

        <Route
          exact
          path="/privacy-policy"
          render={() => (isDesktop ? <Privacy /> : <PrivacyMobile />)}
        />

        <Route
          exact
          path="/terms-of-service"
          render={() => (isDesktop ? <Terms /> : <TermsMobile />)}
        />

        <Route
          exact
          path="/auth"
          render={() =>
            isDesktop ? (
              <SignUp isDesktop={isDesktop} />
            ) : (
              <SignUp isDesktop={isDesktop} />
            )
          }
        />

        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default withRouter(BasePage);
