import React, { Component, useState, useEffect } from "react";
import "../index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { firebase } from "../../../firebase/config";

import { BiChevronDown, BiLinkAlt } from "react-icons/bi";
// import Heading from "./Heading";
import { FiArrowUpRight, FiRefreshCw, FiChevronRight } from "react-icons/fi";
import { FaChevronRight } from "react-icons/fa";
import DashboardNavBarMobile from "../Layout/DashboardNavBarMobile";
import DashboardFooterMobile from "../Layout/DashboardFooterMobile";

const PrivacyMobile = () => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#fff",
          }}
        >
          <MDBCol
            style={{
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 95,
            }}
            size="12"
          >
            <MDBRow
              style={{
                marginTop: 0,
                position: "fixed",
                backgroundColor: "white",
                zIndex: 100,
              }}
            >
              <DashboardNavBarMobile />
            </MDBRow>
            <MDBCol
              className="d-flex justify-content-center"
              style={{ marginTop: 90 }}
              size="12"
            >
              <MDBRow style={{ width: "100vw", paddingLeft: 15 }}>
                <MDBCol size="12">
                  <p style={{ fontSize: 29, fontFamily: "SSBold" }}>
                    Privacy Policy
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 22,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Last Updated: 21/09/2024
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Introduction
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    Welcome to Mimzy. This Privacy Policy outlines how we
                    collect, use, and protect your personal information. By
                    using our services, you agree to the terms of this Privacy
                    Policy.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Information We Collect
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    1. Personal Information: This includes your email address,
                    and payment details.
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    2. Usage Data: We collect data related to your use of our
                    service, such as the type of content you generate.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    How We Use Your Information
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    1. Service Provision: To provide and maintain our services.
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    2. Improvement: To improve and personalize your experience.
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    3. Communication: To communicate with you about updates,
                    promotions, and customer service.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Data Sharing and Third Parties
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    1. We do not sell or share your personal information with
                    third parties for their marketing purposes.
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    2. We may share data with service providers who assist in
                    our operations.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Data Security
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    We implement robust security measures to protect your data.
                    However, no method of transmission or storage is 100%
                    secure.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Cookies
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    We use cookies to enhance your experience. You can opt-out
                    by changing your browser settings.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Your Rights
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    1. You have the right to access, correct, or delete your
                    personal information.
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    2. You can opt-out of receiving marketing communications
                    from us.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Changes to This Policy
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    We may update this Privacy Policy at any time. Changes will
                    be posted on this page and are effective immediately.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Governing Law
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    This Privacy Policy is governed by the laws of the United
                    Kingdom, without regard to its conflict of law principles.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 25,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Contact Us
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    For any questions or concerns about this Privacy Policy,
                    please contact us at help@Mimzy.com
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            {firebase.auth().currentUser ? null : (
              <>
                {/* <Explainer />
                {/* <Process /> */}
                {/* <Steps />
                <Pricing />
                <div style={{ marginTop: 50 }}></div>
                <Questions />  */}
              </>
            )}
          </MDBCol>

          <DashboardFooterMobile />
        </div>
      </div>
    </>
  );
};

export default withRouter(PrivacyMobile);
