import React, { useEffect, useState } from "react";
import {
  Room,
  RoomEvent,
  Track,
  RemoteVideoTrack,
  RemoteTrackPublication,
} from "livekit-client";
import {
  LiveKitRoom,
  GridLayout,
  useTracks,
  ParticipantTile,
} from "@livekit/components-react";
import "@livekit/components-styles";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";

const Stream = () => {
  const url = "wss://liveportrait-odb3ojth.livekit.cloud";
  const [streamAudioAccessToken, setStreamAudioAccessToken] = useState(null);
  const [streamVideoAccessToken, setStreamVideoAccessToken] = useState(null);
  const [voiceSettings, setVoiceSettings] = useState({
    voiceChangerEnabled: false,
    pitchValue: 0,
  });
  const [uid, setUID] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (streamAudioAccessToken) {
      audioListener(streamAudioAccessToken);
    }
  }, [streamAudioAccessToken]);

  useEffect(() => {
    setUID(query.get("uid"));
  }, []);

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        if (!uid) return;

        const userDoc = await firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get();
        if (!userDoc.exists) return;

        const { activeRoom } = userDoc.data();
        if (!activeRoom) return;

        const roomDoc = await firebase
          .firestore()
          .collection("roomQueue")
          .doc(activeRoom)
          .get();
        if (!roomDoc.exists) return;

        const { streamAudioAccessToken, streamVideoAccessToken } =
          roomDoc.data();
        setStreamAudioAccessToken(streamAudioAccessToken);
        setStreamVideoAccessToken(streamVideoAccessToken);
      } catch (error) {
        console.error("Error fetching room data: ", error);
      }
    };

    fetchRoomData();
  }, [uid]);

  // Separate useEffect for listening to real-time voiceSettings changes
  useEffect(() => {
    if (!uid) return;

    let unsubscribe;

    const listenForVoiceSettings = async () => {
      const userDoc = await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get();
      if (!userDoc.exists) return;

      const { activeRoom } = userDoc.data();
      if (!activeRoom) return;

      // Set up snapshot listener for voiceSettings
      unsubscribe = firebase
        .firestore()
        .collection("roomQueue")
        .doc(activeRoom)
        .onSnapshot(
          (docSnapshot) => {
            if (docSnapshot.exists) {
              const { voiceSettings } = docSnapshot.data();
              if (voiceSettings) {
                setVoiceSettings(voiceSettings);
              }
            }
          },
          (error) => {
            console.error("Error listening to voiceSettings: ", error);
          }
        );
    };

    listenForVoiceSettings();

    // Cleanup function to unsubscribe from the snapshot listener
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [uid]);
  const audioListener = async (audioPlayerToken) => {
    try {
      const room = new Room();
      room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
        if (
          track.kind === "audio" &&
          participant.identity === "proxyclone-audio"
        ) {
          const audioElement = new Audio();
          audioElement.muted = true;
          track.attach(audioElement);
          audioElement.play();
        }
      });
      await room.connect(url, audioPlayerToken);
    } catch (error) {
      console.error("Failed to connect to LiveKit room:", error);
    }
  };
  if (!streamVideoAccessToken) {
    return null;
  }

  return (
    <div
      style={{ display: "flex", height: "100vh", backgroundColor: "#1e1e1e" }}
    >
      <LiveKitRoom
        serverUrl={url}
        token={streamVideoAccessToken}
        connect={true}
        video={false}
        audio={false}
      >
        <CityVideoRenderer voiceSettings={voiceSettings} />
      </LiveKitRoom>
    </div>
  );
};

function CityVideoRenderer({ voiceSettings }) {
  // Get the tracks from useTracks
  const tracks = useTracks([
    Track.Source.Camera,
    Track.Source.ScreenShare,
    Track.Source.ScreenShareAudio,
  ]);

  console.log("The tracks are", tracks);

  // Filter out the tracks related to proxyclone-video or camera
  const finalTracks = tracks.filter((track) => {
    const isProxyCloneVideo =
      track.publication.trackName === "proxyclone-video" ||
      track.publication.source === Track.Source.Camera;
    return isProxyCloneVideo;
  });

  // Track when proxyclone-video is published
  useEffect(() => {
    finalTracks.forEach((trackPublication) => {
      if (
        trackPublication.publication instanceof RemoteTrackPublication &&
        trackPublication.publication.kind === "video" &&
        trackPublication.publication.track instanceof RemoteVideoTrack &&
        trackPublication.publication.subscribed
      ) {
        const remoteVideoTrack = trackPublication.publication.track;
        console.log("The remote video track is", remoteVideoTrack);

        if (voiceSettings.voiceChangerEnabled === true) {
          remoteVideoTrack.setPlayoutDelay(1.7);
          // Set the playout delay
        } else {
          remoteVideoTrack.setPlayoutDelay(0);
        }
      }
    });
  }, [finalTracks, voiceSettings]); // Run the effect when finalTracks changes

  return (
    <GridLayout style={{ height: "90vh" }} tracks={finalTracks}>
      <ParticipantTile />
    </GridLayout>
  );
}

export default Stream;
