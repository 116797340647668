import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import MainMenu from "../Layout/MainMenu";
import Logo from "./logo.svg";
import {
  MdCameraAlt,
  MdOutlineRecordVoiceOver,
  MdOutlineContentCopy,
  MdOutlineShare,
} from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { Room, RoomEvent } from "livekit-client";
import { firebase } from "../../../firebase/config";

const InstallationGuide = () => {
  const [copied, setCopied] = useState(false);
  const [roomID, setRoomID] = useState("");
  const [streamURL, setStreamURL] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [sharing, setSharing] = useState(false);
  const [streamAudioAccessToken, setStreamAudioAccessToken] = useState(null);
  const [streamVideoAccessToken, setStreamVideoAccessToken] = useState(null);
  const [screenShareAccessToken, setScreenShareAccessToken] = useState(null);
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    setRoomID(query.get("roomID"));
    setStreamAudioAccessToken(query.get("streamAudioAccessToken"));
    setStreamVideoAccessToken(query.get("streamVideoAccessToken"));
    setScreenShareAccessToken(query.get("screenShareAccessToken"));
  }, []);

  const startControl = async () => {
    if (screenShareAccessToken) {
      const url = "wss://liveportrait-odb3ojth.livekit.cloud";
      const room = new Room();
      await room.connect(url, screenShareAccessToken);
      await room.localParticipant.setScreenShareEnabled(true);
      setSharing(true);

      // Listen for data events and log them to the console
      room.on(RoomEvent.DataReceived, (payload, participant, kind) => {
        // Decode the payload and parse it as JSON
        const decodedPayload = new TextDecoder().decode(payload);

        let data;

        try {
          data = JSON.parse(decodedPayload);
          console.log("Data is", data);
        } catch (error) {
          console.error("Failed to parse payload:", error);
          return;
        }

        console.log("Data received:", {
          payload: data,
          participant,
          kind,
        });

        // Handle different types of events (mouse, keyboard)
        if (data.type === "mouse") {
          window.dispatchEvent(
            new MouseEvent("mousemove", {
              clientX: data.x / 2,
              clientY: data.y / 2,
            })
          );
        } else if (data.type === "keyboard") {
          window.dispatchEvent(
            new KeyboardEvent("keydown", {
              key: data.key,
            })
          );
        }
      });
    }
  };

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            color: "#30312c",
            backgroundColor: "white",
            overflowY: "scroll",
            height: "100vh",
            paddingBottom: 30,
          }}
        >
          {" "}
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <MainMenu />
            </MDBRow>
          </MDBCol>
          <MDBCol
            style={{
              paddingLeft: 0,
              paddingLeft: 0,
              marginTop: 80,
              padddingRight: 0,
            }}
            size="12"
            className="d-flex justify-content-center"
          >
            <MDBRow style={{ paddingLeft: "4%" }}>
              <MDBCol
                style={{ fontFamily: "SSRegular", fontSize: 19 }}
                size="12"
              >
                <p
                  style={{
                    fontFamily: "SSBold",
                    marginTop: 20,
                    textAlign: "center",
                    fontSize: 20,
                  }}
                >
                  Camera Setup
                </p>
                <ol style={{ fontSize: 15 }}>
                  <li style={{ marginTop: 20 }}>
                    Download OBS software (ignore if you have already installed)
                    <br />
                    <div
                      style={{
                        height: 45,
                        width: 280,
                        marginTop: 10,
                        border: "0px solid black",
                        backgroundColor: "blue",
                        color: "white",
                        textAlign: "center",
                        borderRadius: 3,
                        paddingTop: 8.5,
                        fontSize: 19,
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => {
                        window.open(
                          "https://obsproject.com/download",
                          "_blank"
                        );
                      }}
                    >
                      <p>Download OBS</p>
                    </div>
                  </li>
                  <li style={{ marginTop: 20 }}>
                    Open OBS and when prompted click "I will only be using for
                    Virtual Camera":
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage.png?alt=media&token=c5f93c1e-f03a-47a5-938f-b10768817411"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 35 }}>
                    Click "Apply Settings" in the next step.
                  </li>
                  <li style={{ marginTop: 20 }}>
                    Click on the plus sign here:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(1).png?alt=media&token=42401a70-3dbf-49a9-9609-004cb28a1c1f"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Click on Browser as your source:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(2).png?alt=media&token=72b0f025-5441-4f74-a15b-9bb33dc2288a"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    No need to change anything just click ok:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(3).png?alt=media&token=ac5f2901-d3f6-4d3c-a924-a4696ea7bdcd"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20, width: 700 }}>
                    Enter{" "}
                    <p
                      style={{ fontFamily: "SSBold" }}
                      className="d-inline"
                    >{`https://mimzy.me/stream?uid=${
                      firebase.auth().currentUser.uid
                    }`}</p>{" "}
                    into the url field and the width should be 1280 and height
                    should be 720. Then click okay.
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(4).png?alt=media&token=6558d05e-e30e-4373-aad1-031ee27016a1"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Click on Start Virtual Camera
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(5).png?alt=media&token=bcc1e9b0-45fc-40c1-9b2b-cf765bc284f6"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    You should now go to your video calling platform and select
                    "OBS Virtual Camera" as a your camera. And you're ready to
                    go!
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(6).png?alt=media&token=445099af-4196-44f4-8359-e618af7f9310"
                    />
                  </li>{" "}
                </ol>
                {/* <p
                  style={{
                    fontFamily: "SSBold",
                    marginTop: 50,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Microphone Setup
                </p>
                <ol style={{ fontSize: 15 }}>
                  <li style={{ marginTop: 20 }}>
                    Download VB Cable Audio (ignore if you have already
                    installed)
                    <br />
                    <div
                      style={{
                        height: 45,
                        width: 280,
                        marginTop: 10,
                        border: "0px solid black",
                        backgroundColor: "blue",
                        color: "white",
                        textAlign: "center",
                        borderRadius: 3,
                        paddingTop: 8.5,
                        fontSize: 19,
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => {
                        window.open("https://vb-audio.com/Cable/", "_blank");
                      }}
                    >
                      <p>Download VB Cable Audio</p>
                    </div>
                  </li>
                  <li style={{ marginTop: 20 }}>
                    Now unzip this file and click on VBCABLE_Setup_x64 - make
                    sure to right click and Run as Administrator:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(9).png?alt=media&token=4b394bdb-7096-4e22-aa74-0df7605a496e"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Click on Install Driver:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(8).png?alt=media&token=714410fc-a006-4851-bfce-86d3e8108428"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Click Okay to finish this installation:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(10).png?alt=media&token=5a511f73-bfb7-4519-846c-86718915d524"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Select Cable Input as your Microphone:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(11).png?alt=media&token=5ebf994b-aefd-450e-9865-dba02db28ce6"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Open OBS Studio and Click Settings
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(12).png?alt=media&token=213a0624-0aad-441f-be6b-af29074985461"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Click on Audio Settings:
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(13).png?alt=media&token=ae3a04f9-1b84-4d46-a0f1-d2f304362bc8"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Click on Desktop Audio and choose Cable Input
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(14).png?alt=media&token=33a72e48-0710-403a-9567-81e23e5f1a6b"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    Scroll down to Advanced and Click Monitoring Device and
                    Select Cable Input. Then click Apply and Okay.
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(15).png?alt=media&token=3ceb0869-75e5-4d08-8d20-6bde7ead7b7c"
                    />
                  </li>{" "}
                  <li style={{ marginTop: 20 }}>
                    You can now select Cable Input as your Microphone and choose
                    your computer speaker or connected headphone as your output.
                    <br />
                    <img
                      style={{ marginTop: 20 }}
                      height={450}
                      src="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/instructions%2Fimage%20(16).png?alt=media&token=a4f89628-be92-4c83-9bca-6a317a90baed"
                    />
                  </li>{" "}
                </ol> */}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(InstallationGuide);
