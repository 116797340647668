import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { GoQuestion } from "react-icons/go";
import { firebase } from "../../../firebase/config";
import { useAuth } from "../Context/AuthContext";
import { FaCoins } from "react-icons/fa";
import AccountPopUp from "./AccountPopUp";
import Logo from "./mimzy-logo.png";

const MainMenu = () => {
  const { timeLeft } = useAuth();
  const [section, setSection] = useState("library");
  const [accountOpen, setAccountOpen] = useState(false);

  useEffect(() => {
    setAccountOpen(false);
  }, []);

  return (
    <>
      <MDBRow
        style={{
          width: "105vw",
          position: "fixed",
          backgroundColor: "#fff",
          zIndex: 970,
          height: 77,
          borderBottom: "1px solid #eaecf0",
        }}
      >
        <MDBCol
          style={{
            borderBottom: "0px solid rgb(234, 236, 240)",
            height: 77,
            position: "fixed",
            zIndex: 900,
            borderRadius: 0,
            width: "105vw",
            marginTop: 0,
          }}
          className="d-flex justify-content-between"
          size="12"
        >
          <MDBRow style={{ paddingTop: 23 }}>
            <MDBCol size="3">
              <NavLink onClick={() => setAccountOpen(false)} to="/" exact>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 47,
                    width: 47,
                    marginLeft: 15,
                    marginTop: -9,
                    borderRadius: 9,
                  }}
                >
                  <img
                    style={{
                      height: 37,
                      marginTop: 10,
                    }}
                    src={Logo}
                  />
                </div>
              </NavLink>
              <hr
                style={{
                  backgroundColor: "#eaecf0",
                  width: 77,
                  height: 0.3,
                  transform: "rotate(90deg)",
                  position: "absolute",
                  left: 50,
                  marginTop: -24,
                }}
              />
            </MDBCol>
            <MDBCol size="6">
              <div className="d-flex" style={{ marginTop: -4, marginLeft: 28 }}>
                <NavLink
                  exact
                  to="/upgrade"
                  style={{
                    height: 39,
                    border: "1px solid blue",
                    fontFamily: "SSMedium",
                    fontSize: 13,
                    paddingTop: 10,
                    backgroundColor: "rgb(245, 245, 255)",
                    color: "blue",
                    borderRadius: 4,
                    minWidth: 130,
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                  className="d-flex justify-content-center"
                >
                  <p>
                    <FaCoins
                      style={{ fontSize: 14, marginRight: 9, marginTop: -2 }}
                      className="d-inline"
                    />
                    {timeLeft} minutes
                  </p>
                </NavLink>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ paddingTop: 13, marginLeft: -98 }}>
            <NavLink
              to="/"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/" && "rgb(249, 250, 251)",
                marginTop: 10,
                paddingRight: 14,
                paddingLeft: 14,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSRegular",
                fontSize: 13,
                paddingTop: 9,
                display: "inline-block",
                marginRight: 14,
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => setSection("library")}
            >
              <p>Clones</p>
            </NavLink>
            <NavLink
              to="/guide"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/guide" && "rgb(249, 250, 251)",
                marginTop: 10,
                paddingRight: 14,
                paddingLeft: 14,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSRegular",
                fontSize: 13,
                paddingTop: 9,
                display: "inline-block",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => setSection("projects")}
            >
              <p>Instructions for Calls</p>
            </NavLink>
            {/* <NavLink
              to="/voice-clone"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/voice-clone" &&
                  "rgb(249, 250, 251)",
                marginTop: 10,
                paddingRight: 14,
                paddingLeft: 14,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSRegular",
                fontSize: 13,
                paddingTop: 9,
                display: "inline-block",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => setSection("voice-clone")}
            >
              <p>Voice Clones</p>
            </NavLink> */}
          </MDBRow>

          <MDBRow
            style={{
              width: 720,
              marginRight: -440,
              backgroundColor: "transparent",
            }}
          >
            <MDBCol className="d-flex justify-content-between" size="8">
              <div
                style={{
                  height: 39,
                  border: "1px solid rgb(208, 213, 221)",
                  borderRadius: 6,
                  width: 40,
                  marginTop: 18,
                  marginLeft: 38,
                }}
                className="d-flex justify-content-center"
              >
                <a href="mailto:help@tingen.ai" target="_blank">
                  <GoQuestion
                    style={{
                      color: "black",
                      fontSize: 21,
                      cursor: "pointer",
                      marginTop: 9,
                    }}
                  />
                </a>
              </div>
              <NavLink
                style={{
                  height: 39,
                  width: 120,
                  border: "1px solid blue",
                  borderRadius: 6,
                  marginTop: 18,
                  marginLeft: 0,
                  marginRight: 0,
                  backgroundColor: "rgb(245, 245, 255)",
                  opacity: 14,
                  cursor: "pointer",
                }}
                to="/upgrade"
                exact
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    display: "inline-block",
                    fontFamily: "SSRegular",
                    color: "blue",
                    marginTop: 10,
                    marginRight: 0,
                    marginLeft: 0,
                    cursor: "pointer",
                    fontSize: 14,
                    opacity: 1,
                    width: 200,
                    textAlign: "center",
                  }}
                >
                  View plans
                </p>
              </NavLink>

              <div
                style={{
                  height: 39,
                  width: 39,
                  border: "2px solid white",
                  borderRadius: 100,
                  marginTop: 18,
                  marginRight: 190,
                  backgroundColor: "white",
                  opacity: 1,
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <div
                  style={{
                    border: "2px solid #0097a7",
                    height: 37,
                    width: 37,
                    borderRadius: 100,
                    cursor: "pointer",
                    backgroundColor: "#0097a7",
                    color: "white",
                  }}
                  onClick={() => setAccountOpen(true)}
                  className="d-flex justify-content-center"
                >
                  <p
                    style={{
                      fontFamily: "PPBold",
                      fontSize: 18,
                      display: "inline-block",
                      color: "#D8D8D8",
                      marginLeft: 0,
                      cursor: "pointer",
                      marginTop: 4,
                      zIndex: 3000,
                    }}
                  >
                    {firebase.auth().currentUser &&
                      firebase.auth().currentUser.email.charAt(0).toUpperCase()}
                  </p>
                </div>
                <AccountPopUp
                  accountOpen={accountOpen}
                  setAccountOpen={setAccountOpen}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(MainMenu);
