import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { GoQuestion } from "react-icons/go";
import { FaChevronLeft } from "react-icons/fa";

const PricingMenu = () => {
  const [accountOpen, setAccountOpen] = useState(false);

  return (
    <>
      <MDBRow
        style={{
          width: "105vw",
          position: "fixed",
          backgroundColor: "#fff",
          zIndex: 970,
          height: 77,
          borderBottom: "1px solid #eaecf0",
        }}
      >
        <MDBCol
          style={{
            borderBottom: "0px solid rgb(234, 236, 240)",
            height: 77,
            position: "fixed",
            zIndex: 900,
            borderRadius: 0,
            width: "105vw",
            marginTop: 0,
          }}
          className="d-flex justify-content-between"
          size="12"
        >
          <MDBRow style={{ paddingTop: 23 }}>
            <MDBCol size="6">
              <NavLink onClick={() => setAccountOpen(false)} to="/" exact>
                <div
                  style={{
                    display: "flex",
                    marginLeft: 30,
                    color: "black",
                    width: 150,
                    opacity: 0.5,
                    marginTop: 5,
                  }}
                >
                  <FaChevronLeft
                    style={{ marginTop: 4, marginRight: 15, fontSize: 22 }}
                    className="d-inline"
                  />
                  <p style={{ fontFamily: "SSMedium", marginTop: 3 }}>Back</p>
                </div>
              </NavLink>
            </MDBCol>
          </MDBRow>
          <MDBRow
            className="d-flex justify-content-center"
            style={{ paddingTop: 16 }}
          >
            <NavLink
              to="/"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/" && "rgb(249, 250, 251)",
                marginTop: 6,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSMedium",
                fontSize: 20,
                paddingTop: 7,
                display: "inline-block",
                cursor: "pointer",
                color: "black",
                textAlign: "center",
                marginLeft: 80,
              }}
              onClick={() => setSection("library")}
            ></NavLink>
          </MDBRow>

          <MDBRow
            style={{
              width: 720,
              marginRight: -440,
              backgroundColor: "transparent",
            }}
          >
            <MDBCol className="d-flex justify-content-between" size="8">
              <div
                style={{
                  height: 39,
                  border: "1px solid rgb(208, 213, 221)",
                  borderRadius: 6,
                  width: 40,
                  marginTop: 18,
                  marginLeft: 208,
                }}
                className="d-flex justify-content-center"
              >
                <a href="mailto:help@tingen.ai" target="_blank">
                  <GoQuestion
                    style={{
                      color: "black",
                      fontSize: 21,
                      cursor: "pointer",
                      marginTop: 9,
                    }}
                  />
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(PricingMenu);
