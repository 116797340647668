import React, { Component, useState, useEffect } from "react";
import "../index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { firebase } from "../../../firebase/config";

import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";

import { v4 as uuidv4 } from "uuid";
import { FaChevronLeft } from "react-icons/fa";
import Logo from "./mimzy-logo.png";
import { TailSpin } from "react-loader-spinner";

const SignUp = ({ isDesktop }) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [confirmMode, setConfirmMode] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState("");
  const [confirmationError, setConfirmationError] = useState(false);
  const location = useLocation();

  const history = useHistory();

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  useEffect(() => {
    setLoginEmail("");
    setPassword("");
    setError("");
  }, []);

  const signIn = async (Email, Password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(Email, Password)
      .then(() => {
        mixpanel.track("User signed in", {
          "Sign in method": "Email",
        });
        if (firebase.auth()?.currentUser?.emailVerified === true) {
          history.push("/");
        } else {
          setConfirmMode(true);
          setError(null);
        }
      })
      .catch((signUpError) => {
        // Sign up failed
        setLoading(false);
        setError(formatErrorMessage(signUpError.message));
      });
  };

  const signUp = async (Email, Password) => {
    setLoading(true);
    return firebase
      .auth()
      .createUserWithEmailAndPassword(Email, Password)
      .then(async (userCredential) => {
        // Send verification email
        mixpanel.track("User created", {
          "Sign up method": "Email",
        });

        userCredential.user
          .sendEmailVerification()
          .then(() => {
            console.log("Verification email sent.");
          })
          .catch((verificationError) => {
            console.error(
              "Error sending verification email:",
              verificationError
            );
          });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "completeRegistration",
          event_id: uuidv4(), // Replace 'currency' with the transaction currency code
        });

        // Save user data to Firestore
        const uid = userCredential.user.uid;
        const workspaceID = uuidv4();

        if (window.tolt) {
          window.tolt.signup(Email);
        }

        await firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .set({
            email: Email,
            uid: uid,
            activeWorkSpace: workspaceID,
            workspaces: [workspaceID],
          });

        await firebase
          .firestore()
          .collection("workspace")
          .doc(workspaceID)
          .set({
            workspaceID: workspaceID,
          });

        setLoading(false);
      })
      .then(() => {
        setLoading(false);
        setConfirmMode(true);
      })
      .catch((signUpError) => {
        // Sign up failed
        setLoading(false);
        setError(formatErrorMessage(signUpError.message));
      });
  };

  const handleAuthentication = async (user) => {
    setLoading(true);
    const uid = user.uid;

    try {
      const doc = await firebase.firestore().collection("users").doc(uid).get();

      if (!doc.exists) {
        // New user logic
        mixpanel.track("User created", { "Sign up method": "Google" });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "completeRegistration",
          event_id: uuidv4(),
        });

        if (window.tolt) {
          window.tolt.signup(user.email);
        }

        const workspaceID = uuidv4();
        await firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .set({
            email: user.email,
            uid: uid,
            activeWorkSpace: workspaceID,
            workspaces: [workspaceID],
          });

        await firebase
          .firestore()
          .collection("workspace")
          .doc(workspaceID)
          .set({
            workspaceID: workspaceID,
          });

        history.push("/");
      } else {
        // Existing user logic
        mixpanel.track("User signed in", { "Sign in method": "Google" });
        history.push("/");
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitGoogle = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(googleProvider);
      if (result.user) {
        await handleAuthentication(result.user);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
      setLoading(false);
    }
  };

  function formatErrorMessage(errorMessage) {
    // Remove "Firebase:" prefix if it exists
    let formattedMessage = errorMessage.startsWith("Firebase:")
      ? errorMessage.replace("Firebase:", "").trim()
      : errorMessage;

    // Remove error code in parentheses at the end
    formattedMessage = formattedMessage.replace(/\(.*?\)\.?$/, "").trim();

    return formattedMessage;
  }
  if (!isDesktop) {
    return (
      <p
        style={{
          textAlign: "center",
          fontFamily: "SSMedium",
          fontSize: 24,
          marginTop: 190,
        }}
      >
        Please open on a desktop
      </p>
    );
  }

  return (
    <div
      style={{
        overflowX: "hidden",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        paddingBottom: 40,
      }}
      className="d-flex justify-content-center"
    >
      <MDBRow>
        <MDBCol size="12" className="d-flex justify-content-center">
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              paddingLeft: "11%",
              paddingTop: 20,
              fontFamily: "SSBold",
              fontSize: 27,
            }}
            size="12"
          >
            {resetPassword ? (
              <>
                <p>
                  <NavLink to="/" exact>
                    <img
                      style={{
                        height: 27,
                        marginTop: -5.4,
                        marginRight: 5,
                      }}
                      src={Logo}
                    />
                  </NavLink>
                </p>
                <p
                  style={{
                    marginTop: 80,
                    fontFamily: "SSSemiBold",
                    fontSize: 18,
                  }}
                >
                  {finishedReset ? "Check your inbox" : "Forgot your password?"}
                </p>
                {finishedReset ? (
                  <p
                    style={{
                      marginTop: -9,
                      fontFamily: "SSRegular",
                      fontSize: 16,
                      opacity: 0.8,
                    }}
                  >
                    Check your inbox a reset email has been
                    <br /> sent to your inbox. Check your spam folder.
                  </p>
                ) : (
                  <p
                    style={{
                      marginTop: -9,
                      fontFamily: "SSRegular",
                      fontSize: 14,
                      opacity: 0.8,
                    }}
                  >
                    Enter your email address and we will send you
                    <br /> instructions on how to reset your password.
                  </p>
                )}
                {!finishedReset && (
                  <div>
                    <p
                      style={{
                        fontFamily: "SSRegular",
                        fontSize: 14,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      Email
                    </p>
                    <input
                      style={{
                        height: 55,
                        width: 370,
                        marginLeft: 0,
                        border: "1px solid rgba(155, 158, 163, 0.5)",
                        borderRadius: 7,
                        display: "inline-block",
                        color: "black",
                        marginTop: 5,
                        backgroundColor: "white",
                        fontFamily: "SSMedium",
                        paddingLeft: 17,
                        fontSize: 12,
                        paddingTop: 3,
                      }}
                      value={loginEmail}
                      name="Email"
                      onChange={(event) => setLoginEmail(event.target.value)}
                      placeholder="Enter your email"
                    />
                  </div>
                )}
                {!finishedReset && (
                  <div
                    style={{
                      height: 55,
                      width: 370,
                      backgroundColor: "blue",
                      borderRadius: 7,
                      marginTop: 20,
                      textAlign: "center",
                      fontFamily: "SSRegular",
                      color: "white",
                      fontSize: 18,
                      paddingTop: 14,
                      cursor: "pointer",
                      opacity: loginEmail ? 1 : 0.8,
                    }}
                    onClick={() => {
                      if (loginEmail) {
                        firebase.auth().sendPasswordResetEmail(loginEmail);
                        setFinishedReset(true);
                      }
                    }}
                  >
                    <p>Confirm</p>
                  </div>
                )}
                <p
                  style={{
                    marginTop: 20,
                    fontSize: 14,
                    fontFamily: "SSRegular",
                    cursor: "pointer",
                    paddingBottom: "30vh",
                  }}
                  onClick={() => {
                    setResetPassword(false);
                    setFinishedReset(false);
                  }}
                >
                  <FaChevronLeft style={{ marginRight: 5, marginTop: -1.9 }} />
                  Back to Sign In
                </p>
              </>
            ) : confirmMode ? (
              <>
                <p>
                  <NavLink to="/" exact>
                    <img
                      style={{
                        height: 27,
                        marginTop: -5.4,
                        marginRight: 5,
                      }}
                      src={Logo}
                    />
                  </NavLink>
                </p>
                <p
                  style={{
                    marginTop: 110,
                    fontFamily: "SSSemiBold",
                    fontSize: 29,
                  }}
                >
                  Confirm your email
                </p>
                <p
                  style={{
                    marginTop: -9,
                    fontFamily: "SSRegular",
                    fontSize: 16,
                    opacity: 0.8,
                  }}
                >
                  Check your inbox a confirmation link has been
                  <br /> sent to your inbox. Click on this link and come
                  <br /> back when done.
                </p>

                <div
                  style={{
                    height: 55,
                    width: 370,
                    backgroundColor: "blue",
                    borderRadius: 7,
                    marginTop: 20,
                    textAlign: "center",
                    fontFamily: "SSRegular",
                    color: "white",
                    fontSize: 18,
                    paddingTop: 14,
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    const user = firebase.auth().currentUser;
                    if (user) {
                      await user.reload(); // Reload the user to update the emailVerified status
                      if (user.emailVerified) {
                        console.log("Email is verified!");
                        history.push("/"); // Redirect to home or dashboard if needed
                      } else {
                        console.log("Email is not verified.");
                        setConfirmationError(true); // Show an error if not verified
                      }
                    }
                  }}
                >
                  <p>I've confirmed my email</p>
                </div>
                {confirmationError && (
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      fontSize: 14,
                      color: "red",
                      marginTop: 10,
                    }}
                  >
                    Please click on the link in your inbox to continue.
                  </p>
                )}

                <p
                  style={{
                    marginTop: 20,
                    fontSize: 14,
                    fontFamily: "SSRegular",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (firebase.auth().currentUser) {
                      firebase
                        .auth()
                        .signOut()
                        .then(() => {
                          setConfirmMode(false);
                        });
                    } else {
                      setConfirmMode(false);
                    }
                  }}
                >
                  <FaChevronLeft style={{ marginRight: 5, marginTop: -1.9 }} />
                  Back to Sign In
                </p>
              </>
            ) : (
              <>
                <p>
                  {" "}
                  <NavLink to="/" exact>
                    <img
                      style={{
                        height: 27,
                        marginTop: -5.4,
                        marginRight: 5,
                      }}
                      src={Logo}
                    />
                  </NavLink>
                </p>
                <p
                  style={{
                    marginTop: 0,
                    fontFamily: "SSSemiBold",
                    fontSize: 18,
                  }}
                >
                  {signUpMode ? "Welcome to Mimzy" : "Sign in to Mimzy"}
                </p>
                <p
                  style={{
                    marginTop: -9,
                    fontFamily: "SSRegular",
                    fontSize: 14,
                  }}
                >
                  Become anyone during video calls
                </p>
                <div
                  style={{
                    height: 52,
                    width: 370,
                    borderRadius: 6,
                    border: "1px solid rgb(208, 213, 221)",
                    marginTop: 30,
                    fontFamily: "SSRegular",
                    textAlign: "center",
                    fontSize: 15,
                    paddingTop: 14,
                    paddingLeft: 25,
                    cursor: "pointer",
                    boxShadow: "rgba(16, 24, 40, 0.05) 0px 1px 2px",
                    display: "flex",
                  }}
                  onClick={() => onSubmitGoogle()}
                >
                  <img
                    style={{
                      height: 27,
                      top: 0,
                      marginLeft: 70,
                      marginRight: 5,
                      marginTop: -2,
                    }}
                    src={require("./Google.png")}
                  />
                  <p style={{ color: "rgb(52, 64, 84)" }}>
                    {!signUpMode
                      ? "Sign in with Google"
                      : "Sign up with Google"}
                  </p>
                </div>
                <hr
                  style={{
                    width: 370,
                    backgroundColor: "#98a2b3",
                    marginLeft: 0,
                    marginTop: 38,
                  }}
                />
                <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    height: 40,
                    backgroundColor: "white",
                    width: 180,
                    marginTop: -28,
                    marginLeft: 100,
                    fontFamily: "SSRegular",
                    fontSize: 14,
                    textAlign: "center",
                    color: "rgb(102, 112, 133)",
                  }}
                >
                  {signUpMode
                    ? "Or Sign Up with E-mail"
                    : "Or Sign In with E-mail"}
                </div>
                <div>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      fontSize: 14,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Email
                  </p>
                  <input
                    style={{
                      height: 55,
                      width: 370,
                      marginLeft: 0,
                      border: "1px solid rgba(155, 158, 163, 0.5)",
                      borderRadius: 7,
                      display: "inline-block",
                      color: "black",
                      marginTop: 5,
                      backgroundColor: "white",
                      fontFamily: "SSMedium",
                      paddingLeft: 17,
                      fontSize: 12,
                      paddingTop: 3,
                    }}
                    value={loginEmail}
                    name="Email"
                    onChange={(event) => setLoginEmail(event.target.value)}
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      fontSize: 14,
                      marginTop: 16,
                      marginBottom: 0,
                    }}
                  >
                    Password
                  </p>
                  <input
                    style={{
                      height: 55,
                      width: 370,
                      marginLeft: 0,
                      border: "1px solid rgba(155, 158, 163, 0.5)",
                      borderRadius: 7,
                      display: "inline-block",
                      color: "black",
                      marginTop: 5,
                      backgroundColor: "white",
                      fontFamily: "SSMedium",
                      paddingLeft: 17,
                      fontSize: 12,
                      paddingTop: 3,
                    }}
                    type="password"
                    value={password}
                    name="Password"
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Password"
                  />
                </div>
                <p
                  style={{
                    fontFamily: "SSMedium",
                    color: "blue",
                    fontSize: 12,
                    marginTop: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => setResetPassword(true)}
                >
                  Forgot password?
                </p>
                <div
                  style={{
                    height: 55,
                    width: 370,
                    backgroundColor: "blue",
                    borderRadius: 7,
                    marginTop: 20,
                    textAlign: "center",
                    fontFamily: "SSRegular",
                    color: "white",
                    fontSize: 15,
                    paddingTop: 16,
                    cursor: "pointer",
                    opacity: loginEmail && password ? 1 : 0.6,
                  }}
                  className="d-flex justify-content-center"
                  onClick={() => {
                    if (loginEmail && password) {
                      if (signUpMode) {
                        signUp(loginEmail, password);
                      } else {
                        signIn(loginEmail, password);
                      }
                    }
                  }}
                >
                  {loading ? (
                    <TailSpin
                      height="25"
                      width="25"
                      color="#fff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{ marginTop: 0 }}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    <p>{signUpMode ? "Sign up" : "Sign in"}</p>
                  )}
                </div>
                {error && (
                  <p
                    style={{
                      marginTop: 20,
                      fontSize: 14,
                      fontFamily: "SSMedium",
                      color: "red",
                    }}
                  >
                    {error}
                  </p>
                )}
                <p
                  style={{
                    marginTop: 20,
                    fontSize: 12,
                    fontFamily: "SSMedium",
                  }}
                >
                  {!signUpMode
                    ? "Don't have an account?"
                    : "Already have an account?"}
                  <p
                    style={{
                      color: "blue",
                      fontFamily: "SSMedium",
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                    className="d-inline"
                    onClick={() => setSignUpMode(!signUpMode)}
                  >
                    {!signUpMode ? "Sign up" : "Sign in"}
                  </p>
                </p>
                <p
                  style={{
                    marginTop: 20,
                    fontSize: 12,
                    fontFamily: "SSRegular",
                  }}
                >
                  By signing up you agree to Mimzy's privacy policy and terms of
                  service
                </p>
              </>
            )}
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default withRouter(SignUp);
