import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";

import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft, FaShippingFast } from "react-icons/fa";
import { UploadButton } from "@bytescale/upload-widget-react";
import MuxPlayer from "@mux/mux-player-react";
import { RiCloseLine } from "react-icons/ri";

const options = {
  apiKey: "public_FW25cEN3sH3tyFXumovuK72f1JUa", // This is your API key.
  maxFileCount: 1,
  mimeTypes: ["audio/mpeg"],
  maxFileSizeBytes: 524288000,
};

const Step3Digital = ({
  campaignName,
  setSection,
  productURL,
  setProductURL,
  audioURL,
  setAudioURL,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <MDBCol size="12">
      <div>
        <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step1")}
        />

        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 10,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 2 of 3
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 17,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Upload recording
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 11,
            marginTop: 0,
            marginBottom: 0,
            width: 340,
          }}
        >
          Upload a 1 minute voice recording speaking clearly into a microphone.
          Speak with your casual voice and you can use this script for guidance:{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            target="_blank"
            href="https://docs.google.com/document/d/1fyH9pQg9vgeIKK6pkPz_9occeQnS2vz9oIjIkO8dGZM/edit?usp=sharing"
            className="d-inline"
          >
            reading script.
          </a>
        </p>
      </div>
      {audioURL ? (
        <div
          style={{
            marginTop: 20,
            border: "1px dashed black",
            width: 346,
            height: 200,
            borderRadius: 10,
          }}
          className="content"
        >
          <MDBRow style={{ width: 346 }}>
            <RiCloseLine
              onClick={() => setAudioURL("")}
              style={{
                color: "red",
                position: "absolute",
                right: 60,
                marginTop: 20,
                fontSize: 20,
                cursor: "pointer",
                zIndex: 1000,
              }}
            />
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  fontFamily: "SSRegular",
                  color: "black",
                  fontSize: 12,
                  marginTop: 76,
                  textAlign: "center",
                  lineHeight: 1.3,
                  width: "90%",
                }}
              >
                {audioURL.slice(0, 45)} {audioURL.length > 45 && "..."}
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      ) : (
        <UploadButton
          options={options}
          onComplete={(files) => setAudioURL(files[0].fileUrl)}
        >
          {({ onClick }) => (
            <div
              style={{
                marginTop: 20,
                border: "1px dashed black",
                width: 346,
                height: 200,
                borderRadius: 10,
              }}
              onClick={onClick}
              className="content"
            >
              <MDBRow style={{ width: 346, paddingLeft: 16 }}>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSBold",
                      color: "black",
                      fontSize: 15,
                      marginTop: 50,
                      textAlign: "center",
                      lineHeight: 1.3,
                    }}
                  >
                    Upload your recording here
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      color: "black",
                      fontSize: 12,
                      marginTop: -5,
                      textAlign: "center",
                      lineHeight: 1.3,
                      width: "90%",
                    }}
                  >
                    voice recording, must be in mp3 format, 1 minute, clear
                    vocals, Max 500mb
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      color: "black",
                      fontSize: 12,
                      marginTop: 3,
                      textAlign: "center",
                      lineHeight: 1.3,
                      textDecoration: "underline",
                    }}
                  >
                    Browse your local files
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          )}
        </UploadButton>
      )}

      <div
        style={{
          height: 45,
          width: 350,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 14,
          paddingTop: 12,
          cursor: "pointer",
          opacity: audioURL ? 1 : 0.5,
        }}
        onClick={() => {
          if (audioURL) {
            setSection("Step4Digital");
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="20"
            width="20"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step3Digital);
