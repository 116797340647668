import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import MainMenu from "../Layout/MainMenu";
import Templates from "./Templates";

const VoiceClone = () => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#fff",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <MainMenu />
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="d-flex justify-content-center"
            style={{ paddingLeft: 0 }}
            size="12"
          >
            <div style={{ paddingTop: 90, width: 1050 }}>
              <Templates />
            </div>
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(VoiceClone);
