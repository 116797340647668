import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Box = ({ Title, Description, playbackID, imageURL }) => {
  return (
    <div
      style={{
        height: 360,
        width: 330,
        border: "1px solid #eaecf0",
        borderRadius: 15,
        paddingLeft: 25,
        marginRight: 40,
      }}
      size="4"
    >
      <p
        style={{
          fontSize: 27,
          lineHeight: 1.2,
          marginTop: 40,
          fontFamily: "SSMedium",
        }}
      >
        {Title}
      </p>
      <p
        style={{
          fontSize: 15,
          lineHeight: 1.7,
          marginTop: -5,
          opacity: 0.8,
          width: "90%",
          marginLeft: 0,
          fontFamily: "SSRegular",
        }}
      >
        {Description}
      </p>
      <div
        style={{
          width: 330,
          height: 200,
          backgroundColor: imageURL ? "white" : "black",
          borderBottomRightRadius: 15,
          borderBottomLeftRadius: 15,
          marginLeft: -25,
          marginTop: 20,
        }}
      >
        <img
          src={
            imageURL
              ? imageURL
              : `https://image.mux.com/${playbackID}/animated.gif`
          }
          style={{
            height: 200,
            width: 330,
            objectFit: "cover",
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15,
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(Box);
