import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import Logo from "./mimzy-logo.png";

const NavBar = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, []);

  return (
    <>
      <MDBRow
        className="d-flex justify-content-center"
        style={{
          zIndex: 970,
          height: 77,
          position: "sticky",
          backgroundColor: "white",
          top: 0,
          width: "100vw",
        }}
      >
        <MDBCol
          size="12"
          style={{
            display: "flex",
            justifyContent: "space-between", // Ensures the logo and menu items are spread out
            alignItems: "center",
            height: "100%",
            maxWidth: "1050px", // Set a fixed width for the navbar
            margin: "0 auto", // Center the navbar horizontally
          }}
        >
          {/* Left: Logo */}
          <div
            className="navbar-left"
            style={{ display: "flex", alignItems: "center" }}
          >
            <NavLink to="/" exact>
              <img
                src={Logo}
                alt="ProxyClone Logo"
                style={{ height: 29, marginRight: 10 }}
              />
            </NavLink>
            {/* Center: Menu */}
            <div
              className="navbar-center"
              style={{
                display: "flex",
                marginLeft: 60,
                marginTop: 4.5,
                opacity: 0.9,
              }}
            >
              <NavLink
                to="/faq"
                exact
                style={{
                  marginRight: 30,
                  fontFamily: "SSMedium",
                  fontSize: 14,
                  textDecoration: "none",
                  color: "black",
                }}
              >
                FAQ
              </NavLink>

              <a
                href="mailto:support@mimzy.me"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontFamily: "SSMedium",
                  fontSize: 14,
                  textDecoration: "none",
                  color: "black",
                }}
              >
                Contact us
              </a>
            </div>
          </div>

          {/* Right: Login & Book a Demo */}
          <div
            className="navbar-right"
            style={{ display: "flex", alignItems: "center" }}
          >
            <a
              target="_blank"
              href="https://calendly.com/mimzy-demo/30min?month=2024-10"
              style={{
                backgroundColor: "#4f46e5", // Blue background color
                color: "white", // White text color
                fontSize: 15,
                padding: "10px 20px",
                borderRadius: 50, // Rounded button
                textDecoration: "none",
                fontFamily: "SSMedium",
                border: "2px solid white", // Inner white border
                boxShadow: "0 0 0 3px #4f46e5", // Outer blue border effect
              }}
            >
              Get started
            </a>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default NavBar;
