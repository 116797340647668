import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";

const AccountPopUp = ({ accountOpen, setAccountOpen }) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (accountOpen) {
        setAccountOpen(false);
      }
    },
  });

  return (
    <>
      <div
        ref={ref}
        style={{
          paddingBottom: 5,
          width: 200,
          background: "white",
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          paddingLeft: 15,
          display: "flex",
          animation: accountOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: accountOpen ? "visible" : "hidden",
          position: "absolute",
          right: 210,
          marginTop: 180,
          zIndex: 9000,
        }}
        className={`backy60 ${accountOpen ? "popUpAnimation" : ""}`}
      >
        <MDBRow
          style={{ fontFamily: "SSMedium", fontSize: 14, color: "black" }}
        >
          <MDBCol size="12">
            <p style={{ marginTop: 16 }}>Signed in as</p>
            <p style={{ marginTop: -13, fontSize: 12, opacity: 0.8 }}>
              {firebase.auth().currentUser.email.slice(0, 23)}
              {firebase.auth().currentUser.email.length > 17 && "..."}
            </p>
            <hr
              style={{
                width: 200,
                backgroundColor: "grey",
                marginLeft: -15,
                marginTop: -5,
                opacity: 0.1,
              }}
            />

            <p
              onClick={() => firebase.auth().signOut()}
              style={{ marginTop: 0, color: "red", cursor: "pointer" }}
            >
              <AiOutlineLogout
                style={{
                  fontSize: 18,
                  marginRight: 11,
                  marginTop: -2,
                  marginLeft: -5,
                }}
              />
              Sign out
            </p>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default withRouter(AccountPopUp);
