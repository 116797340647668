import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { useAuth } from "../Context/AuthContext";
import { BsPlusLg, BsThreeDots } from "react-icons/bs";
import { MdVideoCall } from "react-icons/md";
import BriefModal from "./BriefModal.jsx";
import { LuPen } from "react-icons/lu";
import ProjectModal from "./ProjectModal";
import { TailSpin } from "react-loader-spinner";
import { GoDeviceCameraVideo } from "react-icons/go";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { FiPlay } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { RiUserVoiceLine } from "react-icons/ri";
import { FaPause, FaPlay } from "react-icons/fa";

const Templates = () => {
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [currentClone, setCurrentClone] = useState(false);
  const [clones, setClones] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [newClonesAvailable, setNewClonesAvailable] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [playingVoice, setPlayingVoice] = useState(null);
  const { workspaceID, paid } = useAuth();

  const audioRef = useRef(null); // Reference to the audio element

  useEffect(() => {
    if (paid === false) {
      setNewClonesAvailable(false);
    } else if (paid === true) {
      setNewClonesAvailable(true);
    }
  }, [paid]);

  const handlePlayPause = (voice) => {
    if (playingVoice === voice) {
      // If the same voice is clicked again, pause it
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setPlayingVoice(null);
    } else {
      // If a different voice is clicked, play it
      if (audioRef.current) {
        audioRef.current.pause(); // Pause the currently playing voice
      }
      const newAudio = new Audio(voice.sample);
      newAudio.play();
      audioRef.current = newAudio; // Set the new audio as the reference
      setPlayingVoice(voice);
    }
  };

  const history = useHistory();

  useEffect(() => {
    if (!isOpen) {
      setCurrentClone(null);
    }
  }, [isOpen]);
  const formatDate = (timestamp) => {
    // Check if the timestamp is a Firestore Timestamp object and convert it to a Date object
    const date =
      timestamp instanceof firebase.firestore.Timestamp
        ? timestamp.toDate()
        : new Date(timestamp); // If it's not a Firestore Timestamp, assume it's a normal date or timestamp

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (workspaceID) {
      firebase
        .firestore()
        .collection("voices")
        .where("workspaceID", "==", workspaceID)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initClones = [];
            querySnapshot.forEach((doc) => {
              initClones.push(doc.data());
            });
            setClones(initClones);
          } else {
            setClones([]);
          }
        });
    }
  }, [workspaceID]);

  function truncateString(str) {
    if (str.length <= 24) {
      return str;
    }
    return str.slice(0, 24) + "...";
  }

  return (
    <>
      <BriefModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        workspaceID={workspaceID}
        currentClone={currentClone}
        setCurrentClone={setCurrentClone}
        isEditMode={isEditMode}
      />
      <MDBRow
        style={{
          width: "100%",
          paddingLeft: 30,
          paddingBottom: 20,
        }}
      >
        <MDBCol style={{ marginTop: 20 }} size="12">
          <MDBRow>
            <MDBCol size="3">
              {newClonesAvailable ? (
                <div
                  style={{
                    height: 180,
                    width: "100%",
                    border: "1px solid #D8D8D8",
                    borderRadius: 12,
                    backgroundColor: "rgb(243, 244, 246)",
                    marginTop: 20,
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsEditMode(false);
                    setIsOpen(true);
                  }}
                >
                  <MDBRow>
                    <MDBCol className="d-flex justify-content-center" size="12">
                      <BsPlusLg style={{ marginTop: 54, fontSize: 18 }} />
                    </MDBCol>
                    <MDBCol className="d-flex justify-content-center" size="12">
                      <p
                        style={{
                          marginTop: 6,
                          fontSize: 14,
                          textAlign: "center",
                          fontFamily: "SSBold",
                        }}
                      >
                        Create a Voice Clone
                      </p>
                    </MDBCol>
                    <MDBCol className="d-flex justify-content-center" size="12">
                      <p
                        style={{
                          marginTop: -9,
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "SSRegular",
                        }}
                      >
                        Train a new voice
                      </p>
                    </MDBCol>
                  </MDBRow>
                </div>
              ) : (
                <NavLink to="/upgrade" exact>
                  <div
                    style={{
                      height: 180,
                      width: "100%",
                      border: "1px solid #D8D8D8",
                      borderRadius: 12,
                      backgroundColor: "rgb(243, 244, 246)",
                      marginTop: 20,
                      color: "black",
                      cursor: "pointer",
                    }}
                  >
                    <MDBRow>
                      <MDBCol
                        className="d-flex justify-content-center"
                        size="12"
                      >
                        <HiOutlineEmojiSad
                          style={{ marginTop: 54, fontSize: 18 }}
                        />
                      </MDBCol>
                      <MDBCol
                        className="d-flex justify-content-center"
                        size="12"
                      >
                        <p
                          style={{
                            marginTop: 6,
                            fontSize: 14,
                            textAlign: "center",
                            fontFamily: "SSBold",
                          }}
                        >
                          You need to upgrade
                        </p>
                      </MDBCol>
                      <MDBCol
                        className="d-flex justify-content-center"
                        size="12"
                      >
                        <p
                          style={{
                            marginTop: -9,
                            fontSize: 12,
                            textAlign: "center",
                            fontFamily: "SSRegular",
                          }}
                        >
                          Upgrade to clone voices.
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </NavLink>
              )}
            </MDBCol>
            {clones.map((clone, index) => {
              const borderProgress = clone.Progress; // Assuming 'progress' is the variable you have
              const gradient = `linear-gradient(to right, blue ${borderProgress}%, #E7E7FC ${borderProgress}%, #E7E7FC 100%)`;

              return (
                <>
                  <MDBCol size="3">
                    <div
                      className="project-card"
                      style={{
                        borderRadius: 12,
                        marginTop: 20,
                        cursor: "pointer",
                        paddingRight: 4,
                      }}
                      onMouseLeave={() => setProjectModalOpen(false)}
                    >
                      <div className="image-container">
                        <div
                          style={{
                            width: 356.6,
                            height: 186,
                            objectFit: "cover",
                            borderRadius: 10.5,
                            backgroundColor: "black",
                            marginLeft: index % 4 === 0 ? 0.4 : 0,
                            borderBottom:
                              clone.status === "Processing"
                                ? `6px solid`
                                : null, // Removed the gradient from here
                            borderImage:
                              clone.status === "Processing"
                                ? `${gradient} 1 / 0 0 6px 0`
                                : null, // Applying gradient only to the bottom border
                          }}
                          className="d-flex justify-content-center"
                        >
                          <RiUserVoiceLine
                            style={{
                              color: "white",
                              fontSize: 40,
                              marginTop: 60,
                            }}
                          />
                        </div>
                      </div>
                      {clone.status === "Error" && (
                        <div
                          style={{
                            position: "absolute",
                            height: 40,
                            width: 40,
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            marginTop: -116,
                            marginLeft: 160,
                          }}
                          className="d-flex justify-content-center edit-icon"
                        >
                          <LuPen style={{ color: "black", marginTop: 12 }} />
                        </div>
                      )}

                      {clone.status === "Processing" && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              height: 30,
                              width: 30,
                              borderRadius: "50%",
                              backgroundColor: "#fff",
                              marginTop: -46,
                              marginLeft: 10,
                            }}
                            className="d-flex justify-content-center"
                          >
                            <TailSpin
                              height="17"
                              width="17"
                              color="blue"
                              ariaLabel="tail-spin-loading"
                              radius="3"
                              wrapperStyle={{ marginTop: 2 }}
                              wrapperClass="d-inline"
                              visible={true}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              height: 30,
                              paddingLeft: 10,
                              paddingRight: 10,
                              borderRadius: 90,
                              backgroundColor: "white",
                              marginTop: -46,
                              marginLeft: 50,
                              fontFamily: "SSMedium",
                              fontSize: 14,
                              paddingTop: 4,
                              color: "blue",
                            }}
                            className="d-flex justify-content-center"
                          >
                            {clone.stage}
                          </div>
                        </>
                      )}
                      <div
                        style={{
                          position: "absolute",
                          height: 25,
                          width: 30,
                          borderRadius: 8,
                          backgroundColor: "#fff",
                          top: 35,
                          right: 10,
                          display:
                            clone.status === "Finished" ? "block" : "none",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentClone(clone.voiceID);
                          setProjectModalOpen(true);
                        }}
                        className="d-flex justify-content-center dots-icon"
                      >
                        <BsThreeDots
                          style={{
                            color: "black",
                            marginTop: 2,
                            fontSize: 22,
                          }}
                        />
                      </div>
                      {currentClone === clone.voiceID && (
                        <ProjectModal
                          projectModalOpen={projectModalOpen}
                          setProjectModalOpen={setProjectModalOpen}
                          cloneID={clone.voiceID}
                          setIsOpen={setIsOpen}
                          workspaceID={workspaceID}
                        />
                      )}

                      {clone.status === "Error" && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: "#FFCCCB",
                            fontFamily: "SSRegular",
                            textAlign: "center",
                            fontSize: 12,
                            marginTop: -30,
                            marginLeft: 10,
                            width: 50,
                            color: "red",
                            height: 21,
                            paddingTop: 1.2,
                          }}
                          className="d-flex justify-content-center"
                        >
                          Error
                        </div>
                      )}
                      <div className="d-flex justify-content-between">
                        <div
                          style={{
                            fontFamily: "SSRegular",
                            marginLeft: 12,
                            marginTop: 10,
                          }}
                        >
                          <p>
                            {clone.name.slice(0, 30)}
                            {clone.name.length > 30 ? "..." : ""}
                          </p>
                          <p
                            style={{
                              fontSize: 11,
                              opacity: 0.7,
                              marginTop: -15,
                            }}
                          >
                            Voice Clone
                          </p>
                        </div>
                        {clone.sample && (
                          <div
                            style={{
                              fontFamily: "SSRegular",
                              marginLeft: -15,
                              marginTop: 10,
                              marginRight: 10,
                            }}
                          >
                            {playingVoice === clone ? (
                              <FaPause
                                style={{
                                  display: "inline-block",
                                  fontSize: 20,
                                  marginTop: 11,
                                  marginRight: 30,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlePlayPause(clone);
                                }}
                              />
                            ) : (
                              <FaPlay
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlePlayPause(clone);
                                }}
                                style={{
                                  display: "inline-block",
                                  fontSize: 20,
                                  marginTop: 11,
                                  marginRight: 30,
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </MDBCol>
                </>
              );
            })}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Templates);
