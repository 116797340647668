import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { RiCloseLine } from "react-icons/ri";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2Digital from "./Step2Digital";
import Step3Digital from "./Step3Digital";
import Step4Digital from "./Step4Digital";
import { useAuth } from "../Context/AuthContext";

const BriefModal = ({
  setIsOpen,
  isOpen,
  videoID,
  workspaceID,
  currentClone,
  isEditMode,
  setCurrentClone,
}) => {
  const [cloneType, setCloneType] = useState("Interview");
  const [section, setSection] = useState("Step1");
  const [cloneName, setCloneName] = useState("");
  const [description, setDescription] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [voices, setVoices] = useState([]);
  const [error, setError] = useState(null);
  const [videoURL, setVideoURL] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [voiceType, setVoiceType] = useState("");
  const {
    fullTimeClones,
    fullTimeClonesUsed,
    interviewClones,
    interviewClonesUsed,
  } = useAuth();

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const globalVoicesRef = firebase.firestore().collection("voices");

        // Fetch global voices
        const globalVoicesSnapshot = await globalVoicesRef.get();
        let globalVoices = [];
        if (!globalVoicesSnapshot.empty) {
          globalVoicesSnapshot.forEach((doc) => {
            globalVoices.push(doc.data());
          });
        }

        // Merge both, placing workspace voices at the beginning
        const mergedVoices = [...globalVoices];

        // Set the voices to state
        setVoices(mergedVoices);
      } catch (error) {
        console.error("Error fetching voices:", error);
      }
    };

    if (workspaceID) {
      fetchVoices();
    }
  }, [workspaceID]);

  useEffect(() => {
    if (workspaceID && currentClone) {
      firebase
        .firestore()
        .collection("clones")
        .doc(currentClone)
        .get()
        .then((doc) => {
          const { cloneName, videoURL, voiceID, cloneType } = doc.data();
          setCloneName(cloneName);
          setVideoURL(videoURL);
          setVoiceID(voiceID);
          setCloneType(cloneType);
        });
    }
  }, [currentClone, workspaceID]);

  useEffect(() => {
    if (!isEditMode) {
      if (interviewClonesUsed === interviewClones) {
        setCloneType("FullTime");
      }
      if (fullTimeClones - fullTimeClonesUsed <= 0) {
        setCloneType("Interview");
      }
    }
  }, [interviewClonesUsed, fullTimeClonesUsed, isEditMode]);

  useEffect(() => {
    if (!isOpen) {
      // Reset all states to their default values when modal is closed
      setSection("Step1");
      setCloneName("");
      setDescription("");
      setLoginEmail("");
      setLoginPassword("");
      setCurrentClone(null);
      setVideoURL("");
      setVoiceID("");
    }
  }, [isOpen]); // Dependency array, effect will run when isOpen changes

  useEffect(() => {
    if (isOpen && !currentClone) {
    } else if (isOpen && currentClone) {
      setSection("Step1");
    }
  }, [isOpen, currentClone]);

  const submitClone = async () => {
    setError(null);
    if (!workspaceID) {
      return null;
    } else {
      if (currentClone) {
        await updateClone();
      } else {
        await createClone();
      }
    }
  };

  const updateClone = async () => {
    if (!currentClone || !workspaceID) return;

    // Get the current clone data from Firebase
    try {
      const doc = await firebase
        .firestore()
        .collection("clones")
        .doc(currentClone)
        .get();

      if (!doc.exists) {
        console.log("No such clone exists!");
        return;
      }

      const originalData = doc.data();
      const updatedFields = {};

      // Compare original values with current state and add only changed fields to the update object
      if (cloneName !== originalData.cloneName) {
        updatedFields.cloneName = cloneName;
      }
      if (cloneType !== originalData.cloneType) {
        updatedFields.cloneType = cloneType;
      }
      if (videoURL !== originalData.videoURL) {
        updatedFields.videoURL = videoURL;
      }
      // if (voiceID !== originalData.voiceID) {
      //   updatedFields.voiceID = voiceID;
      // }

      // Check if there are any updated fields
      if (Object.keys(updatedFields).length === 0) {
        console.log("No fields have been changed.");
        return;
      }

      // Call the firebase functions to update the clone
      const updateCloneCallable = firebase
        .functions()
        .httpsCallable("updateClone");
      await updateCloneCallable({
        cloneId: currentClone,
        workspaceID: workspaceID,
        updatedFields: updatedFields,
      });

      console.log("Clone updated successfully!");
      setIsOpen(false); // Close the modal after successful update
    } catch (error) {
      console.error("Error updating clone:", error);
      setIsOpen(false); // Optionally close the modal even if there is an error
    }
  };

  const createClone = async () => {
    if (workspaceID) {
      var cloneActor = firebase.functions().httpsCallable("cloneActor");
      cloneActor({
        videoURL,
        cloneName,
        // voiceID,
        cloneType,
        // voiceType,
      })
        .then(async (result) => {
          if (result.data.status === "Success") {
            setIsOpen(false);
          }
        })
        .catch((e) => {
          // Check for specific error codes and set error messages accordingly
          if (e.code === "resource-exhausted") {
            if (cloneType === "FullTime") {
              setError(
                "Not enough full-time clone credits. Please upgrade your plan or remove an existing clone."
              );
            } else if (cloneType === "Interview") {
              setError(
                "Not enough interview clone credits. Please upgrade your plan or remove an existing clone."
              );
            }
          } else {
            setError(
              "An error occurred while creating the clone. Please try again."
            );
          }
          console.log(e);
        });
    }
  };

  const renderSection = (section) => {
    const uid = firebase.auth().currentUser.uid;
    switch (section) {
      // case "Step0":
      //   return (
      //     <Step0
      //       setSection={setSection}
      //       cloneType={cloneType}
      //       setCloneType={setCloneType}
      //       cloneName={cloneName}
      //       setCloneName={setCloneName}
      //       isEditMode={isEditMode}
      //     />
      //   );
      case "Step1":
        return (
          <Step1
            setSection={setSection}
            cloneName={cloneName}
            setCloneName={setCloneName}
          />
        );
      case "Step2Digital":
        return (
          <Step2Digital
            uid={uid}
            videoURL={videoURL}
            setVideoURL={setVideoURL}
            setSection={setSection}
          />
        );
      // case "Step3Digital":
      //   return (
      //     <Step3Digital
      //       uid={uid}
      //       setSection={setSection}
      //       description={description}
      //       setDescription={setDescription}
      //       voiceID={voiceID}
      //       setVoiceID={setVoiceID}
      //       voices={voices}
      //       isOpen={isOpen}
      //       setVoiceType={setVoiceType}
      //     />
      //   );
      case "Step4Digital":
        return (
          <Step4Digital
            uid={uid}
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            loginPassword={loginPassword}
            setLoginPassword={setLoginPassword}
            setSection={setSection}
            submitClone={submitClone}
            error={error}
            currentClone={currentClone}
          />
        );
    }
  };

  return (
    <SkyLightStateless
      hideOnOverlayClicked={true}
      onOverlayClicked={async () => {
        setIsOpen(false);
      }}
      onCloseClicked={async () => {
        setIsOpen(false);
      }}
      isVisible={isOpen}
      overlayStyles={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100%",
        zIndex: 9000,
        opacity: 0.6,
        backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
        backdropFilter: "blur(10px)", // Blur effect for the background elements
      }}
      dialogStyles={{
        zIndex: 9000,
        position: "fixed",
        width: "100vw",
        minHeight: "60px",
        marginTop: "0%",
        top: "0%",
        height: "100vh",
        boxShadow: "none",
        overflowY: "scroll",
        left: "0%",
        right: "0%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "rgba(255, 255, 255, 0.04)", // Semi-transparent white for the glass effect
        border: "1px solid transparent", // Slight border for depth
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for more depth
        backdropFilter: "blur(6px)", // Apply blur to the dialog for the glass effect
      }}
      closeButtonStyle={{
        color: "white",
        right: 650,
        position: "absolute",
        marginTop: 90,
        zIndex: 9600,
        fontSize: 50,
        height: 60,
        width: 60,
        display: "none",
      }}
    >
      <div
        style={{
          paddingBottom: 40,
          width: 400,
          backgroundColor: "#fff",
          borderRadius: 13,
          zIndex: 90000,
          top: "3.5%",
          position: "absolute",
          left: "5%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
          border: "1px solid rgb(234, 236, 240)",
          paddingLeft: 30,
          paddingTop: 30,
        }}
        className="backy60"
      >
        <MDBRow>
          <MDBCol className="d-flex justify-content-end" size="12">
            <RiCloseLine
              onClick={() => setIsOpen(false)}
              style={{
                marginRight: 20,
                fontSize: 30,
                color: "rgb(102, 112, 133)",
                cursor: "pointer",
              }}
            />
          </MDBCol>
          {renderSection(section)}
        </MDBRow>
      </div>
    </SkyLightStateless>
  );
};

export default withRouter(BriefModal);
