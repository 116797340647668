import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./Components/Parts/Context/AuthContext";
import { LoadingProvider } from "./Components/Parts/Context/LoadingContext.jsx";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// import register from './registerServiceWorker';

if (module.hot) {
  module.hot.accept();
}

const stripePromise = loadStripe(
  "pk_live_51O9InDHLaTqDI0ZJxEuxNO9oRhMT6GXZp7lwQYJG518JtdGJV0Tfx84zzl6pI5oe9G0UcwzrWER9tJj4dqOVm3Yd00oZqtXWXs",
  {
    fonts: [
      {
        src: require("./Assets/Fonts/font/MierA-Bold.ttf"),
        family: "MABold",
        style: "normal",
      },
    ],
  }
);

// const stripePromise = loadStripe(
//   "pk_test_51O9InDHLaTqDI0ZJt9X9inyeqysaKIpQAtw1qnPdMjBINSboLSkFgCXXYSCn6wpGmeJ5IeSeRizcIPF3Cydxb4xA00ECeab2eK",
//   {
//     fonts: [
//       {
//         src: require("./Assets/Fonts/font/MierA-Bold.ttf"),
//         family: "MABold",
//         style: "normal",
//       },
//     ],
//   }
// );
ReactDOM.render(
  <Elements stripe={stripePromise}>
    <LoadingProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </LoadingProvider>
  </Elements>,
  document.getElementById("root")
);
