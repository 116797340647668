import React, { Component, useState, useEffect } from "react";
import "./index.css";
import { firebase } from "./firebase/config";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BasePage from "./Components/Page/BasePage";
import Layout from "./Components/Parts/Layout/layout";
import MobileLayout from "./Components/Parts/Layout/mobileLayout";
import mixpanel from "mixpanel-browser";
import { UserContext } from "./UserContext"; // Update the path accordingly
mixpanel.init("5c79e89c51c2fbf44863bc2fd5a111a8", { debug: true });

const App = () => {
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 1000);
  }, []);

  return (
    <Router>
      <BasePage />
    </Router>
  );
};

export default App;
