import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { useAuth } from "../Context/AuthContext";
import { BsPlusLg, BsThreeDots } from "react-icons/bs";
import { MdVideoCall } from "react-icons/md";
import BriefModal from "./BriefModal.jsx";
import { LuPen } from "react-icons/lu";
import ProjectModal from "./ProjectModal";
import { TailSpin } from "react-loader-spinner";
import { GoDeviceCameraVideo } from "react-icons/go";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { FiPlay } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

const Templates = () => {
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [currentClone, setCurrentClone] = useState(false);
  const [clones, setClones] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [newClonesAvailable, setNewClonesAvailable] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const { workspaceID, paid } = useAuth();
  const [interviewClones, setInterviewClones] = useState(null);
  const [fullTimeClones, setFullTimeClones] = useState(null);
  const [fullTimeClonesUsed, setFullTimeClonesUsed] = useState(null);
  const [interviewClonesUsed, setInterviewClonesUsed] = useState(null);

  const history = useHistory();

  // Set up the workspace listener to count interview and full-time clones used
  useEffect(() => {
    if (workspaceID) {
      // Listen for changes in the workspace document
      firebase
        .firestore()
        .collection("workspace")
        .doc(workspaceID)
        .onSnapshot((doc) => {
          if (doc.data()) {
            setFullTimeClones(doc.data().fullTimeClones || 0);
            setInterviewClones(doc.data().interviewClones || 0);
          }
        });

      // Set up a listener for the clones subcollection to count used clones
      const unsubscribeClones = firebase
        .firestore()
        .collection("clones")
        .where("workspaceID", "==", workspaceID)
        .onSnapshot((snapshot) => {
          let interviewClonesCount = 0;
          let fullTimeClonesCount = 0;

          snapshot.forEach((doc) => {
            const data = doc.data();
            const interviewCloneInit = doc.data().interviewClones;
            if (data.cloneType === "Interview" && interviewCloneInit !== 0) {
              interviewClonesCount++;
            } else if (data.cloneType === "FullTime") {
              fullTimeClonesCount++;
            }
          });

          if (!paid) {
            setInterviewClones(0);
          } else {
            setInterviewClonesUsed(interviewClonesCount);
          }
          // Update the state with the counts

          setFullTimeClonesUsed(fullTimeClonesCount);
        });

      // Clean up the listener on unmount
      return () => {
        if (unsubscribeClones) {
          unsubscribeClones();
        }
      };
    }
  }, [workspaceID, paid]);

  useEffect(() => {
    console.log("Interview clones used is", interviewClonesUsed);
    console.log("Interview clones is", interviewClones);

    if (interviewClonesUsed >= interviewClones || interviewClones === 0) {
      setNewClonesAvailable(false);
    } else {
      setNewClonesAvailable(true);
    }
  }, [interviewClonesUsed, interviewClones]);

  const formatDate = (timestamp) => {
    // Check if the timestamp is a Firestore Timestamp object and convert it to a Date object
    const date =
      timestamp instanceof firebase.firestore.Timestamp
        ? timestamp.toDate()
        : new Date(timestamp); // If it's not a Firestore Timestamp, assume it's a normal date or timestamp

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const deleteClone = async (cloneID) => {
    await firebase.firestore().collection("clones").doc(cloneID).delete();
  };

  useEffect(() => {
    if (workspaceID) {
      firebase
        .firestore()
        .collection("clones")
        .where("workspaceID", "==", workspaceID)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initClones = [];
            querySnapshot.forEach((doc) => {
              initClones.push(doc.data());
            });
            setClones(initClones);
          } else {
            setClones([]);
          }
        });
    }
  }, [workspaceID]);

  function truncateString(str) {
    if (str.length <= 24) {
      return str;
    }
    return str.slice(0, 24) + "...";
  }

  return (
    <>
      <BriefModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        workspaceID={workspaceID}
        currentClone={currentClone}
        setCurrentClone={setCurrentClone}
        isEditMode={isEditMode}
      />
      <MDBRow
        style={{
          width: "100%",
          paddingLeft: 30,
          paddingBottom: 20,
        }}
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <div
            style={{
              height: 59,
              border: "1px solid blue",
              fontFamily: "SSBold",
              fontSize: 13,
              paddingTop: 8,
              backgroundColor: "#F5F5FF",
              color: "blue",
              borderRadius: 4,
              minWidth: 160,
              cursor: "pointer",
              width: "100%",
              marginTop: 20,
              borderRadius: 10,
            }}
            className="d-flex justify-content-between"
          >
            <div style={{ marginTop: 13, marginLeft: 20 }}>
              <p>Follow our guide to use during video calls.</p>
            </div>
            <div>
              <NavLink
                exact
                to="/guide"
                style={{
                  height: 38,
                  background: "blue",
                  borderRadius: 7,
                  fontFamily: "SSMedium",
                  paddingTop: 8,
                  fontSize: 14,
                  cursor: "pointer",
                  color: "white",
                  paddingRight: 25,
                  paddingLeft: 25,
                  marginRight: 20,
                  marginTop: 2,
                }}
                className="d-flex justify-content-center"
              >
                <p>Setup for Calls</p>
              </NavLink>
            </div>
          </div>
        </MDBCol>

        <MDBCol style={{ marginTop: 20 }} size="12">
          <MDBRow>
            <MDBCol size="4">
              <div
                style={{
                  height: 180,
                  width: "100%",
                  border: "1px solid #D8D8D8",
                  borderRadius: 12,
                  backgroundColor: "rgb(243, 244, 246)",
                  marginTop: 20,
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsEditMode(false);
                  setIsOpen(true);
                }}
              >
                <MDBRow>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <BsPlusLg style={{ marginTop: 43, fontSize: 19 }} />
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        marginTop: 6,
                        fontSize: 16,
                        textAlign: "center",
                        fontFamily: "SSBold",
                      }}
                    >
                      Create a Clone
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        marginTop: -9,
                        fontSize: 13,
                        textAlign: "center",
                        fontFamily: "SSRegular",
                      }}
                    >
                      Start joining calls as a clone
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
            {clones.map((clone, index) => {
              const borderProgress = clone.Progress; // Assuming 'progress' is the variable you have
              const gradient = `linear-gradient(to right, blue ${borderProgress}%, #E7E7FC ${borderProgress}%, #E7E7FC 100%)`;

              return (
                <MDBCol size="4">
                  <div
                    style={{
                      borderRadius: 12,
                      marginTop: 20,
                      paddingRight: 0,
                      width: "100%",
                      border: "1px solid #D8D8D8",
                      paddingBottom: 10,
                    }}
                  >
                    <div className="image-container">
                      <img
                        className="image-container"
                        src={`https://image.mux.com/${clone.playbackID}/thumbnail.png`}
                        style={{
                          width: "100%",
                          height: 120,
                          objectFit: "cover",
                          borderTopRightRadius: 10.5,
                          borderTopLeftRadius: 10.5,
                          marginLeft: index % 4 === 0 ? 0.4 : 0,
                          borderBottom:
                            clone.status === "Processing" ? `6px solid` : null, // Removed the gradient from here
                          borderImage:
                            clone.status === "Processing"
                              ? `${gradient} 1 / 0 0 6px 0`
                              : null, // Applying gradient only to the bottom border
                        }}
                      />
                    </div>
                    {clone.status === "Error" && (
                      <div
                        style={{
                          position: "absolute",
                          height: 40,
                          width: 40,
                          borderRadius: "50%",
                          backgroundColor: "#fff",
                          marginTop: 56,
                          marginLeft: 160,
                        }}
                        className="d-flex justify-content-center edit-icon"
                      >
                        <LuPen style={{ color: "black", marginTop: 12 }} />
                      </div>
                    )}

                    {clone.status === "Processing" && (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            height: 30,
                            width: 30,
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            marginTop: -46,
                            marginLeft: 10,
                          }}
                          className="d-flex justify-content-center"
                        >
                          <TailSpin
                            height="17"
                            width="17"
                            color="blue"
                            ariaLabel="tail-spin-loading"
                            radius="3"
                            wrapperStyle={{ marginTop: 2 }}
                            wrapperClass="d-inline"
                            visible={true}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: 30,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 90,
                            backgroundColor: "white",
                            marginTop: -46,
                            marginLeft: 50,
                            fontFamily: "SSMedium",
                            fontSize: 14,
                            paddingTop: 4,
                            color: "blue",
                          }}
                          className="d-flex justify-content-center"
                        >
                          {clone.stage}
                        </div>
                      </>
                    )}

                    {currentClone === clone.cloneID && (
                      <ProjectModal
                        projectModalOpen={projectModalOpen}
                        setProjectModalOpen={setProjectModalOpen}
                        cloneID={clone.cloneID}
                        setIsOpen={setIsOpen}
                        workspaceID={workspaceID}
                      />
                    )}

                    {clone.status === "Error" && (
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#FFCCCB",
                          fontFamily: "SSRegular",
                          textAlign: "center",
                          fontSize: 12,
                          marginTop: -30,
                          marginLeft: 10,
                          width: 50,
                          color: "red",
                          height: 21,
                          paddingTop: 1.2,
                        }}
                        className="d-flex justify-content-center"
                      >
                        Error
                      </div>
                    )}
                    <div className="d-flex justify-content-between">
                      <div
                        style={{
                          fontFamily: "SSRegular",
                          marginLeft: 12,
                          marginTop: 14,
                          fontSize: 13,
                        }}
                      >
                        <p>
                          {clone.cloneName.slice(0, 30)}
                          {clone.cloneName.length > 30 ? "..." : ""}
                        </p>
                        <p
                          style={{
                            fontSize: 10,
                            opacity: 0.7,
                            marginTop: -15,
                          }}
                        >
                          Created at{" "}
                          {clone.createdAt && formatDate(clone.createdAt)}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      <MDBRow>
                        <MDBCol size="12">
                          <div
                            style={{
                              height: 40,
                              width: "95%",
                              backgroundColor: "#32de84",
                              borderRadius: 5,
                              textAlign: "center",
                              paddingTop: 12,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (clone.status === "Finished") {
                                history.push(
                                  `/call?roomID=${uuidv4()}&cloneID=${
                                    clone.cloneID
                                  }&voiceID=${clone.voiceID}&voiceType=${
                                    clone.voiceType
                                  }`
                                );
                              }
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "SSMedium",
                                color: "white",
                                fontSize: 13,
                                paddingTop: 0,
                              }}
                            >
                              Start Session
                            </p>
                          </div>
                          <div
                            style={{
                              height: 40,
                              width: "95%",
                              backgroundColor: "#6366f1",
                              borderRadius: 5,
                              textAlign: "center",
                              paddingTop: 12,
                              marginTop: 7,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsEditMode(true);
                              setCurrentClone(clone.cloneID);
                              setIsOpen(true);
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "SSMedium",
                                color: "white",
                                fontSize: 13,
                                paddingTop: 0,
                              }}
                            >
                              Edit Clone
                            </p>
                          </div>
                          <div
                            style={{
                              height: 40,
                              width: "95%",
                              backgroundColor: "red",
                              borderRadius: 5,
                              textAlign: "center",
                              paddingTop: 12,
                              marginTop: 7,
                              cursor: "pointer",
                            }}
                            onClick={async () =>
                              await deleteClone(clone.cloneID)
                            }
                          >
                            <p
                              style={{
                                fontFamily: "SSMedium",
                                color: "white",
                                fontSize: 13,
                                paddingTop: 0,
                              }}
                            >
                              Delete Clone
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                </MDBCol>
              );
            })}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Templates);
