import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";
import { FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";
import { SlSpeech } from "react-icons/sl";
import { useAuth } from "../Context/AuthContext";

const Step0 = ({
  setIsOpen,
  isOpen,
  videoID,
  cloneType,
  setCloneType,
  setSection,
  isEditMode,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    fullTimeClones,
    fullTimeClonesUsed,
    interviewClones,
    interviewClonesUsed,
  } = useAuth();

  useEffect(() => {
    if (interviewClonesUsed === interviewClones) {
      setCloneType("FullTime");
    }
    if (fullTimeClones === fullTimeClonesUsed) {
      setCloneType("Interview");
    }
  }, [
    interviewClonesUsed,
    fullTimeClonesUsed,
    fullTimeClones,
    interviewClones,
  ]);

  return (
    <MDBCol size="12">
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 12,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 0 of 4
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          {isEditMode ? "Cannot change clone type" : "Choose your clone type"}
        </p>

        <div
          onClick={() => {
            if (!isEditMode && interviewClonesUsed < interviewClones) {
              setCloneType("Interview");
            }
          }}
          style={{
            height: 65,
            width: 410,
            borderRadius: 3,
            marginTop: 20,
            fontFamily: "SSRegular",
            color: "black",
            paddingTop: 10,
            cursor:
              !isEditMode && interviewClonesUsed < interviewClones
                ? "pointer"
                : null,
            opacity:
              !isEditMode &&
              interviewClonesUsed < interviewClones &&
              cloneType === "Interview"
                ? 1
                : 0.6,
            border:
              interviewClonesUsed < interviewClones && cloneType === "Interview"
                ? "2px solid blue"
                : "2px solid #eaecf0",
            paddingLeft: 50,
            backgroundColor:
              interviewClonesUsed < interviewClones &&
              !isEditMode &&
              cloneType === "Interview"
                ? "#F8F8F8"
                : "transparent",
          }}
          className="d-flex justify-content-between"
        >
          <SlSpeech
            style={{
              position: "absolute",
              left: 30,
              fontSize: 20,
              marginTop: 13,
              color: cloneType === "Interview" ? "blue" : "black",
            }}
          />

          <FiArrowRight
            style={{
              position: "absolute",
              right: 80,
              fontSize: 20,
              marginTop: 13,
              color: cloneType === "Interview" ? "blue" : "black",
            }}
          />

          <div>
            <p style={{ fontSize: 17, fontFamily: "SSMedium" }}>
              Interview{" "}
              {interviewClonesUsed < interviewClones
                ? isEditMode && cloneType === "Interview"
                  ? "- Cannot change type"
                  : null
                : "- Out of Clones"}
            </p>
            <p style={{ fontSize: 13, marginTop: -20 }}>
              You need this clone for interviews. 2 hours a month.
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            if (!isEditMode && fullTimeClonesUsed < fullTimeClones) {
              setCloneType("FullTime");
            }
          }}
          style={{
            height: 65,
            width: 410,
            borderRadius: 3,
            marginTop: 20,
            fontFamily: "SSRegular",
            color: "black",
            paddingTop: 10,
            cursor:
              !isEditMode && fullTimeClonesUsed < fullTimeClones
                ? "pointer"
                : null,
            opacity:
              !isEditMode &&
              fullTimeClonesUsed < fullTimeClones &&
              cloneType === "FullTime"
                ? 1
                : 0.6,
            border:
              fullTimeClonesUsed < fullTimeClones && cloneType === "FullTime"
                ? "2px solid blue"
                : "2px solid #eaecf0",
            paddingLeft: 50,
            backgroundColor:
              !isEditMode &&
              fullTimeClonesUsed < fullTimeClones &&
              cloneType === "FullTime"
                ? "#F8F8F8"
                : "transparent",
          }}
          className="d-flex justify-content-between"
        >
          <MdOutlinePhonelink
            style={{
              position: "absolute",
              left: 30,
              fontSize: 20,
              marginTop: 13,
              color:
                !isEditMode &&
                fullTimeClonesUsed < fullTimeClones &&
                cloneType === "FullTime"
                  ? "blue"
                  : "black",
            }}
          />

          <FiArrowRight
            style={{
              position: "absolute",
              right: 80,
              fontSize: 20,
              marginTop: 13,
              color:
                !isEditMode &&
                fullTimeClonesUsed < fullTimeClones &&
                cloneType === "FullTime"
                  ? "blue"
                  : "black",
            }}
          />

          <div>
            <p style={{ fontSize: 17, fontFamily: "SSMedium" }}>
              Full-time{" "}
              {fullTimeClonesUsed < fullTimeClones
                ? isEditMode && cloneType === "FullTime"
                  ? "- Cannot change type"
                  : null
                : "- Out of Clones"}
            </p>
            <p style={{ fontSize: 13, marginTop: -20 }}>
              You need this clone full-time. 40 hours a month.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: cloneType ? "pointer" : null,
          opacity: cloneType ? 1 : 0.6,
        }}
        onClick={() => {
          if (
            (interviewClonesUsed === interviewClones ||
              fullTimeClonesUsed < fullTimeClones) &&
            cloneType
          ) {
            setSection("Step1");
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step0);
