import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import { firebase } from "../../../firebase/config";

import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft, FaShippingFast } from "react-icons/fa";

const Step4Digital = ({
  uid,
  setSection,
  submitClone,
  error,
  currentClone,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  return (
    <MDBCol size="12">
      <div>
        <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step3Digital")}
        />

        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 10,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 3 of 3
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 17,
            marginTop: 10,
            marginBottom: 0,
            width: 340,
          }}
        >
          {currentClone ? "Update Clone" : "Submit Clone"}
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 11,
            marginTop: 0,
            marginBottom: 0,
            width: 340,
          }}
        >
          {currentClone
            ? "Once your click update we will update your clone instantly, and you can start using it for your calls."
            : "Once you click submit we will create your clone instantly, and you can start using it for your calls."}
        </p>
      </div>
      <div
        style={{
          height: 45,
          width: 350,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 14,
          paddingTop: 12,
          cursor: !loading ? "pointer" : null,
          opacity: !loading ? 1 : 0.5,
        }}
        onClick={() => {
          if (!loading) {
            setLoading(true);
            submitClone();
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="20"
            width="20"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>{currentClone ? <p>Update</p> : <p>Submit</p>}</>
        )}
      </div>
      <div>
        <p style={{ color: "red", marginTop: 10 }}>{error}</p>
      </div>
    </MDBCol>
  );
};

export default withRouter(Step4Digital);
