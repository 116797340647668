import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useDetectClickOutside } from "react-detect-click-outside";
import { TailSpin } from "react-loader-spinner";
import { LuPen } from "react-icons/lu";

const DownloadPopup = ({
  projectModalOpen,
  setProjectModalOpen,
  cloneID,
  setIsOpen,
  workspaceID,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (projectModalOpen) {
        setProjectModalOpen(false);
      }
    },
  });

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const deleteClone = async () => {
    setDeleteLoading(true);
    await firebase.firestore().collection("voices").doc(cloneID).delete();

    setProjectModalOpen(false);
  };

  useEffect(() => {
    setDeleteLoading(false);
  }, [projectModalOpen]);
  return (
    <>
      <div
        ref={ref}
        style={{
          width: 170,
          borderRadius: 8,
          top: 0,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: projectModalOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: projectModalOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 70,
          zIndex: 11000,
          border: "none",
          marginLeft: 0,
          backgroundColor: "white",
          display: "flex",
          paddingTop: 18,
          paddingBottom: 5,
          paddingLeft: 18,
          paddingRight: 5,
          color: "#181818",
          opacity: 1,
          fontFamily: "SSRegular",
          fontSize: 15,
          right: 11,
        }}
        className={`backy60   alwayson ${
          projectModalOpen ? "popUpAnimation" : ""
        }`}
      >
        <MDBRow>
          {
            <MDBCol size="12">
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  setProjectModalOpen(false);
                  setIsOpen(true);
                }}
                style={{ cursor: "pointer", marginTop: 6 }}
              >
                {editLoading ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#181818"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{ marginTop: -6, marginRight: 12 }}
                    wrapperClass="d-inline"
                    visible={true}
                  />
                ) : (
                  <LuPen
                    style={{
                      marginRight: 13,
                      fontSize: 17,
                      marginTop: -3.6,
                      width: 20,
                    }}
                  />
                )}
                Edit
              </p>
            </MDBCol>
          }
          {
            <MDBCol size="12">
              <p
                onClick={async (e) => {
                  e.stopPropagation();
                  if (cloneID && workspaceID) {
                    await deleteClone();
                  }
                }}
                style={{ cursor: "pointer", marginTop: 6 }}
              >
                {deleteLoading ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#181818"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{ marginTop: -6, marginRight: 12 }}
                    wrapperClass="d-inline"
                    visible={true}
                  />
                ) : (
                  <RiDeleteBin7Line
                    style={{
                      marginRight: 13,
                      fontSize: 17,
                      marginTop: -3.6,
                      width: 20,
                    }}
                  />
                )}
                Delete
              </p>
            </MDBCol>
          }
        </MDBRow>
      </div>
    </>
  );
};

export default withRouter(DownloadPopup);
