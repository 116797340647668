import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft, FaShippingFast } from "react-icons/fa";
import { UploadButton } from "@bytescale/upload-widget-react";
import MuxPlayer from "@mux/mux-player-react";
import { RiCloseLine } from "react-icons/ri";

const options = {
  apiKey: "public_12a1z9R4ZummfzyhdnC5kUFyhL7a", // This is your API key.
  maxFileCount: 1,
  mimeTypes: ["video/mp4"],
  maxFileSizeBytes: 524288000,
};

const Step2Digital = ({ setSection, videoURL, setVideoURL }) => {
  const [loading, setLoading] = useState(false);

  return (
    <MDBCol size="12">
      <div>
        <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step1")}
        />

        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 10,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 2 of 3
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 17,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Upload a 20 second video
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 11,
            marginTop: 0,
            marginBottom: 0,
            width: 340,
          }}
        >
          Upload a 20 second video with your mouth closed throughout and move
          slightly around in your seat to look realistic. Follow the example in
          the video below.
        </p>
      </div>
      <MuxPlayer
        streamType="on-demand"
        accent-color="blue"
        thumbnailTime={3}
        playbackId="qHcm3h6GHbczLJsRP3uu5MM9FkL6km2W7B7jNyw3RlY"
        style={{
          display: "inline-block",
          borderRadius: 20,
          objectFit: "cover",
          verticalAlign: "top",
          backgroundColor: "transparent", // Added this line
          aspectRatio: 16 / 9,
          width: 346,
          backgroundColor: "black",
          marginTop: 20,
          borderRadius: 3,
          thumbnailTime: 3,
        }}
      />

      {videoURL ? (
        <div
          style={{
            marginTop: 20,
            border: "1px dashed black",
            width: 346,
            height: 200,
            borderRadius: 10,
          }}
          className="content"
        >
          <MDBRow style={{ width: 346 }}>
            <RiCloseLine
              onClick={() => setVideoURL("")}
              style={{
                color: "red",
                position: "absolute",
                right: 60,
                marginTop: 20,
                fontSize: 20,
                cursor: "pointer",
                zIndex: 1000,
              }}
            />
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  fontFamily: "SSRegular",
                  color: "black",
                  fontSize: 12,
                  marginTop: 76,
                  textAlign: "center",
                  lineHeight: 1.3,
                  width: "90%",
                }}
              >
                {videoURL.slice(0, 45)}
                {videoURL.length > 45 && "..."}
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      ) : (
        <UploadButton
          options={options}
          onComplete={(files) => setVideoURL(files[0].fileUrl)}
        >
          {({ onClick }) => (
            <div
              style={{
                marginTop: 20,
                border: "1px dashed black",
                width: 346,
                height: 200,
                borderRadius: 10,
              }}
              onClick={onClick}
              className="content"
            >
              <MDBRow style={{ width: 346, paddingLeft: 16 }}>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSBold",
                      color: "black",
                      fontSize: 15,
                      marginTop: 50,
                      textAlign: "center",
                      lineHeight: 1.3,
                    }}
                  >
                    Upload your video here
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      color: "black",
                      fontSize: 12,
                      marginTop: -5,
                      textAlign: "center",
                      lineHeight: 1.3,
                      width: "90%",
                    }}
                  >
                    landscape video, must be in mp4 format, 20 seconds, 720p-4K
                    resolution, Max 500mb
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      color: "black",
                      fontSize: 12,
                      marginTop: 3,
                      textAlign: "center",
                      lineHeight: 1.3,
                      textDecoration: "underline",
                    }}
                  >
                    Browse your local files
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          )}
        </UploadButton>
      )}

      <div
        style={{
          height: 45,
          width: 350,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 14,
          paddingTop: 12,
          cursor: "pointer",
          opacity: videoURL ? 1 : 0.5,
        }}
        onClick={() => {
          if (videoURL) {
            setSection("Step4Digital");
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step2Digital);
