import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";

import Box from "./Box";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Section2 = () => {
  return (
    <MDBCol className="d-flex justify-content-center" size="12">
      <div
        style={{
          height: 460,
          width: 1100,
          marginTop: 90,
          borderRadius: 9,
          padding: 0,
          justifyContent: "space-evenly",
          display: "flex",
          paddingLeft: 10,
        }}
      >
        <Box
          playbackID="RR4p0001ZPyOOI18VKIpkaFsNHhIyQ3MJKRdMutrBvjno"
          Title="Full control"
          Description="Control expressions and head movements with accurate lipsync."
        />
        <Box
          playbackID="oZRCWE01nK2xI287PraJ02sxyG8jwlSZtvi5abhJANvns"
          Title="Instant Clone"
          Description="Record yourself for 10 seconds to create your instant clone."
          imageURL="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/adin%2FTINGEN.png?alt=media&token=78f40bf4-e4bc-42c2-9787-447e371cfb79"
        />
        <Box
          Title="No installation"
          Description="No hardware needed. Use our web platform to get started."
          playbackID="01H7b500VcsoOxv9NWow8UP1GtgOW9tPhgijD4Cdtapuw"
          imageURL="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/adin%2FTINGEN%20(1).png?alt=media&token=c6cd3167-7b4c-4751-8037-d1ffb2f62a0c"
        />
      </div>
    </MDBCol>
  );
};

export default withRouter(Section2);
