import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const PricingMobile = () => {
  const [currentFullTimeClones, setCurrentFullTimeClones] = useState(0);
  const [currentInterviewClones, setCurrentInterviewClones] = useState(0);

  return (
    <>
      <MDBRow style={{ marginTop: 0, paddingBottom: 50, paddingTop: 30 }}>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              fontFamily: "SSBold",
              fontSize: 46,
              marginTop: 0,
              textAlign: "center",
              color: "black",
              lineHeight: 1,
            }}
          >
            Pricing
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              color: "#30312c",
              fontFamily: "SSMono",
              fontSize: 20,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            Become anyone during calls for a simple price.
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            style={{ marginTop: 20 }}
            className="d-flex justify-content-center"
          >
            <MDBCol className="d-flex justify-content-center" size="12">
              <MDBRow
                className="d-flex justify-content-center"
                style={{
                  width: "100vw",
                  marginTop: 0,
                  color: "#30312c",
                  paddingRight: 0,
                  paddingLeft: 0,
                  userSelect: "none",
                }}
              >
                <MDBCol className="d-flex justify-content-center" size="12">
                  <div
                    style={{
                      paddingLeft: 20,
                      fontFamily: "PPMedium",
                      paddingLeft: 20,
                      width: "90vw",
                      paddingTop: 20,
                      borderRadius: 12,
                      border: "1px solid rgb(234, 236, 240)",
                      backgroundColor: "#fff",
                      height: 250,
                    }}
                  >
                    <p style={{ fontFamily: "SSMedium", fontSize: 19 }}>
                      Interview Clone - 2500 INR per month
                    </p>
                    <p
                      style={{
                        fontFamily: "SSRegular",
                        fontSize: 14,
                        marginTop: -13,
                      }}
                    >
                      Interview clones can be used up to{" "}
                      <p style={{ fontFamily: "SSBold" }} className="d-inline">
                        2 hours a month.
                      </p>
                    </p>
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "85%" }}
                    >
                      <div
                        style={{
                          height: 50,
                          width: 50,
                          borderRadius: 2000,
                          border: "1px solid blue",
                          fontSize: 30,
                          color: "blue",
                          paddingTop: 9,
                          cursor: "pointer",
                          marginTop: 14,
                        }}
                        onClick={() => {
                          if (currentInterviewClones > 0) {
                            setCurrentInterviewClones((prev) => prev - 1);
                          }
                        }}
                        className="d-flex justify-content-center"
                      >
                        <AiOutlineMinus />
                      </div>
                      <p
                        style={{
                          fontSize: 30,
                          fontFamily: "SSMedium",
                          marginTop: 14,
                        }}
                      >
                        {currentInterviewClones} clones
                      </p>
                      <div
                        style={{
                          height: 50,
                          width: 50,
                          borderRadius: 2000,
                          border: "1px solid blue",
                          fontSize: 30,
                          color: "blue",
                          paddingTop: 7,
                          cursor: "pointer",
                          marginTop: 14,
                        }}
                        onClick={() =>
                          setCurrentInterviewClones((prev) => prev + 1)
                        }
                        className="d-flex justify-content-center"
                      >
                        <AiOutlinePlus />
                      </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <p
                        style={{
                          fontFamily: "SSMedium",
                          fontSize: 28,
                          marginTop: 30,
                          marginLeft: 5,
                        }}
                        className="d-inline"
                      >
                        ₹{currentInterviewClones * 2500}{" "}
                        <p
                          className="d-inline"
                          style={{
                            fontFamily: "SSRegular",
                            fontSize: 14,
                            opacity: 0.7,
                          }}
                        >
                          / month
                        </p>
                      </p>
                    </div>
                  </div>
                </MDBCol>
                {/* <MDBCol className="d-flex justify-content-center" size="6">
                  <div
                    style={{
                      paddingLeft: 20,
                      fontFamily: "PPMedium",
                      paddingLeft: 20,
                      width: 577,
                      paddingTop: 20,
                      borderRadius: 12,
                      border: "1px solid rgb(234, 236, 240)",
                      backgroundColor: "#fff",
                      height: 250,
                    }}
                  >
                    <p style={{ fontFamily: "SSMedium", fontSize: 24 }}>
                      Full-time Clone - 8370 INR per month
                    </p>
                    <p
                      style={{
                        fontFamily: "SSRegular",
                        fontSize: 15,
                        marginTop: -13,
                      }}
                    >
                      Full-time clones can be used up to{" "}
                      <p style={{ fontFamily: "SSBold" }} className="d-inline">
                        40 hours a month.
                      </p>
                    </p>
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "70%" }}
                    >
                      <div
                        style={{
                          height: 60,
                          width: 60,
                          borderRadius: 2000,
                          border: "1px solid blue",
                          fontSize: 30,
                          color: "blue",
                          paddingTop: 13,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (currentFullTimeClones > 0) {
                            setCurrentFullTimeClones((prev) => prev - 1);
                          }
                        }}
                        className="d-flex justify-content-center"
                      >
                        <AiOutlineMinus />
                      </div>
                      <p
                        style={{
                          fontSize: 30,
                          fontFamily: "SSMedium",
                          marginTop: 5,
                        }}
                      >
                        {currentFullTimeClones} clones
                      </p>
                      <div
                        style={{
                          height: 60,
                          width: 60,
                          borderRadius: 2000,
                          border: "1px solid blue",
                          fontSize: 30,
                          color: "blue",
                          paddingTop: 13,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setCurrentFullTimeClones((prev) => prev + 1)
                        }
                        className="d-flex justify-content-center"
                      >
                        <AiOutlinePlus />
                      </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <p
                        style={{
                          fontFamily: "SSMedium",
                          fontSize: 28,
                          marginTop: 30,
                          marginLeft: 5,
                        }}
                        className="d-inline"
                      >
                        ₹{currentFullTimeClones * 8370}{" "}
                        <p
                          className="d-inline"
                          style={{
                            fontFamily: "SSRegular",
                            fontSize: 14,
                            opacity: 0.7,
                          }}
                        >
                          / month
                        </p>
                      </p>
                    </div>
                  </div>
                </MDBCol> */}
                <MDBCol className="d-flex justify-content-center" size="12">
                  <NavLink exact to="/auth">
                    <div
                      style={{
                        height: 60,
                        width: "90vw",
                        backgroundColor: "blue",
                        marginTop: 20,
                        borderRadius: 7,
                        paddingTop: 18,
                        color: "white",
                        fontFamily: "SSMedium",
                        fontSize: 19,
                        opacity: 1,
                        cursor: "pointer",
                      }}
                      className="d-flex justify-content-center"
                    >
                      <p>Get started</p>
                    </div>
                  </NavLink>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default PricingMobile;
