import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";

const Questions = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [iconVisible, setIconVisible] = useState(true);
  const [openIndex, setOpenIndex] = useState(null);
  const videoRef = useRef();

  const faqs = [
    {
      question: "How does Mimzy work?",
      answer:
        "Mimzy allows you to instantly change your appearance and voice during video calls on platforms like Google Meet and Zoom. Users simply upload a 10-second video of themselves to create their clone. Once the clone is set up, Mimzy synchronizes your video and voice in real-time during the call, ensuring seamless transitions.",
    },
    {
      question: "What do we mean by 'real-time transformations'?",
      answer:
        "Real-time transformations refer to Mimzy’s ability to synchronize your facial expressions, lip movements, and voice instantly with your clone during video calls. This ensures that your appearance is continuously updated to match natural conversations, creating an authentic experience throughout the session.",
    },
    {
      question: "What is the process for creating a clone with Mimzy?",
      answer:
        "Creating a clone with Mimzy is simple. All you need to do is record a short 10-second video of yourself, which the system uses to generate your clone. Once ready, your clone can be used in real-time during video calls, with Mimzy handling the synchronization of expressions and voice.",
    },
    {
      question: "How long does it take to create a clone using Mimzy?",
      answer:
        "Mimzy is designed for quick setup. It only takes a 10-second video of yourself to create your clone, and the system processes it within a few minutes, making your clone ready for use in video calls almost immediately.",
    },
    {
      question: "Is the clone customizable?",
      answer:
        "While Mimzy uses your own recorded video to create the clone, you can adjust aspects such as how your voice is synchronized and how facial expressions are matched during real-time transformations to ensure an accurate representation.",
    },
    {
      question: "Can I use Mimzy on any platform?",
      answer:
        "Yes, Mimzy works seamlessly with most video conferencing platforms, including Google Meet and Zoom. Once your clone is created, it can be activated across different platforms to maintain consistency in your appearance and voice.",
    },
  ];

  const toggleOpen = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const handleMouseEnter = (item) => {
    setCurrentItem(item);
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0; // Rewind the video to the beginning when pausing
      setCurrentItem(null);
    }
  };

  return (
    <>
      <MDBRow
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: 100,
        }}
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            style={{
              marginTop: 90,
              width: "80%",
              backgroundColor: "white",
              borderRadius: 12,
              paddingBottom: 50,
              overflowY: "hidden",
              border: "1px solid blue",
              paddingLeft: 0,
            }}
          >
            <MDBCol className="d-flex justify-content-start" size="12">
              <MDBRow style={{ marginTop: 80 }}>
                {faqs.map((faq, index) => (
                  <>
                    <MDBCol
                      key={index}
                      className="d-flex justify-content-start"
                      size="12"
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <div
                        onClick={() => toggleOpen(index)}
                        style={{
                          color: "black",
                          fontFamily: "SSMedium",
                          fontSize: 19,
                          marginLeft: 0,
                          lineHeight: 1,
                          opacity: 1,
                          cursor: "pointer",
                          userSelect: "none",
                          paddingTop: 5,
                          paddingBottom: 40,
                          width: "100%",
                          paddingLeft: 50,
                          paddingRight: 50,
                        }}
                        className="d-flex justify-content-between"
                      >
                        <p>{faq.question}</p>{" "}
                        <div
                          style={{
                            height: 50,
                            width: 50,
                            backgroundColor: "white",
                            borderRadius: 100,
                            marginTop: -16,
                          }}
                          className="d-flex justify-content-center"
                        >
                          <AiOutlinePlus
                            style={{ color: "#30312c", marginTop: 11 }}
                          />
                        </div>
                      </div>
                    </MDBCol>
                    <MDBCol
                      key={index}
                      className="d-flex justify-content-start"
                      size="12"
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                      }}
                    >
                      <div
                        style={{
                          color: "black",
                          fontFamily: "SSRegular",
                          fontSize: 18,
                          marginTop: openIndex === index ? 0 : 0,
                          marginLeft: 0,
                          lineHeight: 1.6,
                          opacity: 0.7,
                          borderBottom: "1px solid grey",
                          width: "100%",
                          marginBottom: 40,
                          paddingBottom: openIndex === index ? 50 : 0,
                          paddingLeft: 50,
                          paddingRight: 50,
                          marginBottom: index === 5 ? 0 : 40,
                        }}
                      >
                        {openIndex === index && <div>{faq.answer}</div>}
                      </div>
                    </MDBCol>
                  </>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Questions);
