import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";

import BoxMobile from "./BoxMobile";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Section4Mobile = () => {
  return (
    <MDBCol
      style={{ marginTop: -10 }}
      className="d-flex justify-content-center"
      size="12"
    >
      <div
        style={{
          width: "100vw",
          marginTop: 40,
          borderRadius: 9,
          padding: 0,
          paddingLeft: 0,
        }}
      >
        <MDBRow>
          <MDBCol className="d-flex justify-content-center" size="12">
            <BoxMobile
              Title="Any platform"
              Description="Works on Google Meets, Zoom and any video calling platform."
              playbackID="00l6mzYrwU5XHgiiHhhFap02njL48MBPme202jOvi902w500"
              imageURL="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/adin%2F360_F_398881351_yGVRttyBAXplBUVpkx8mDPAGIambbQvP.jpg?alt=media&token=7ad9bcd9-2ecc-414e-aec2-cba2a6f57970"
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <BoxMobile
              Title="Voice Changer"
              playbackID="n1BIvbYAY5uAvo8sBv32lUhZ732eUWev3Z54stlXEDY"
              Description="Accurate voice changer in real-time during call."
              imageURL="https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/adin%2FTINGEN%20(2).png?alt=media&token=127906f5-f4cc-40d3-8bf7-b5425562e8e2"
            />
          </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
  );
};

export default withRouter(Section4Mobile);
