import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { firebase } from "../../../firebase/config";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";

function checkPlanExists(plans, tier, planType) {
  // Convert tier and planType to their corresponding values
  const name = `${tier} Plan`;
  const interval = planType === "Weekly" ? "month" : "year";

  if (!plans) {
    return null;
  }

  // Check if the plan exists in the array
  return plans.some((plan) => plan.name === name && plan.interval === interval);
}

function PriceHeading({
  tier,
  planType,
  subscribedPlans,
  setIsOpen,
  price,
  monthly,
}) {
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [active, setActive] = useState(null);
  const [workspaceData, setWorkspaceData] = useState(null);
  const { workspaceID } = useAuth();

  useEffect(() => {
    // Assuming `monthly` is a state or prop that indicates if the current view/context is monthly
    // `price` should be defined in your component or passed as props if needed
    if (workspaceData) {
      const isActive =
        ((workspaceData.planType === "Monthly" && monthly) ||
          (workspaceData.planType !== "Monthly" && !monthly)) &&
        (price.ads === workspaceData.creditsRenewing ||
          price.ads * 12 === workspaceData.creditsRenewing) &&
        tier !== "Enterprise";

      setActive(isActive);
    } else {
      setActive(false);
    }
  }, [monthly, workspaceData, price, workspaceID]);

  useEffect(() => {
    if (workspaceID) {
      firebase
        .firestore()
        .collection("workspace")
        .doc(workspaceID)
        .onSnapshot((doc) => {
          setWorkspaceData(doc.data());
        });
    }
  }, [workspaceID]);

  const pricingToStripe = (price) => {
    switch (price) {
      case 39:
        return "price_1Nlu2EKXhAhuLzvpeX8cZe2d";
      case 29:
        return "price_1Nlu2EKXhAhuLzvpmuqoDhRz";
      case 119:
        return "price_1Nlu2JKXhAhuLzvpJ0ohCIwK";
      case 100:
        return "price_1Nlu2JKXhAhuLzvpCIuS5FZT";
      case 299:
        return "price_1Nlu2NKXhAhuLzvpGdrLINui";
      case 500:
        return "price_1Nlu2NKXhAhuLzvpGdrLINui";
    }
  };

  const priceMappings = {
    // Starter tier prices
    10000: "price_1Q42P7HLaTqDI0ZJNrln2zXf", // Monthly price for first starter plan
    8000: "price_1Q42QYHLaTqDI0ZJvisi7fAa", // Annual price for first starter plan
    20000: "price_1Q42S7HLaTqDI0ZJKnHte5U0", // Monthly price for second starter plan
    16000: "price_1Q42TKHLaTqDI0ZJSzQ9wU53", // Annual price for second starter plan

    // Growing tier prices
    27000: "price_1Q42UuHLaTqDI0ZJxfbfTD12", // Monthly price for first growing plan
    21600: "price_1Q42VyHLaTqDI0ZJNRaiEDIV", // Annual price for first growing plan (same as monthly for another tier, so adjusted)
    54000: "price_1Q42XiHLaTqDI0ZJvQeMtcMK", // Monthly price for second growing plan
    43200: "price_1Q42ahHLaTqDI0ZJQyKXvGjB", // Annual price for second growing plan
    81000: "price_1Q42cBHLaTqDI0ZJgXScEgtk", // Monthly price for third growing plan
    64800: "price_1Q42deHLaTqDI0ZJpsuc55pC", // Annual price for third growing plan

    // Scale tier prices
    2: "price_1Q42fbHLaTqDI0ZJiEdixPxA", // Monthly price for first scale plan
    1.6: "price_1Q42ioHLaTqDI0ZJvsaM34hP", // Annual price for first scale plan
    3.6: "price_1Q42kJHLaTqDI0ZJTCStK9xF", // Monthly price for second scale plan
    2.4: "price_1Q42lIHLaTqDI0ZJFE2GjIM7", // Annual price for second scale plan
    4: "price_1Q42mxHLaTqDI0ZJ1NnXRytg", // Monthly price for third scale plan
    3.2: "price_1Q42o2HLaTqDI0ZJ4xqKqSmE", // Annual price for third scale plan
    16: "price_1Q42pxHLaTqDI0ZJ5ZtymsVR", // Monthly price for fourth scale plan
    12.8: "price_1Q42rTHLaTqDI0ZJXrg7HdSr", // Annual price for fourth scale plan
  };

  const startSubscription = async (tier) => {
    setSubscriptionLoading(true);
    const uid = firebase.auth().currentUser.uid;
    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        price: priceMappings[monthly ? price.monthly : price.annual],
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const subheadingText = (plan) => {
    switch (plan) {
      case "Starter":
        return "Using it casually or just getting started with ProxyClone.";
        break;
      case "Growing":
        return "Serious regular usage of ProxyClone for calling.";
        break;
      case "Scale":
        return "Using ProxyClone at scale across different users regularly.";
        break;
      case "Enterprise":
        return "For large workforces needing to use ProxyClone for their enterprise.";
        break;
    }
  };

  return (
    <MDBRow>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 17,
            fontFamily: "SSMedium",
            marginTop: -17,
          }}
        >
          {tier}
        </p>
      </MDBCol>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 15,
            fontFamily: "SSMedium",
            marginTop: -10,
            opacity: 0.7,
            width: 250,
          }}
        >
          {subheadingText(tier)}
        </p>
      </MDBCol>
      <MDBCol size="12">
        <div
          style={{
            fontSize: 54,
            fontFamily: "SSMedium",
            marginTop: -12,
            color: "#30312c",
            display: "flex",
          }}
        >
          <p
            style={{
              fontSize: 27,
              fontFamily: "SSMono",
              marginBottom: 0,
              marginRight: 5,
              position: "absolute",
              marginTop: 30,
            }}
            className="d-inline"
          >
            {tier !== "Enterprise" && "₹"}
          </p>

          <p
            style={{ marginLeft: tier === "Enterprise" ? 0 : 20 }}
            className="d-inline"
          >
            {tier !== "Enterprise"
              ? monthly
                ? price.monthly
                : price.annual
              : "Custom"}
          </p>
          {tier === "Scale" && (
            <p
              style={{
                fontSize: 35,
                marginLeft: 6,
                marginTop: 19.3,
              }}
            >
              lakh
            </p>
          )}
          <p
            style={{
              fontSize: 12,
              fontFamily: "SSSemiBold",
              marginTop: monthly ? 45 : 30,
              marginLeft: 19,
            }}
          >
            {tier !== "Enterprise" && !monthly && "Billed annually"}
            {tier !== "Enterprise" && !monthly && <br />}
            {tier !== "Enterprise" && "/ per month"}
          </p>
        </div>
        <p style={{ fontFamily: "SSSemiBold", fontSize: 15, marginTop: -15 }}>
          {tier !== "Enterprise"
            ? `${price.ads} credits. ${price.templates} instant clones included.`
            : "1000+ credits. Unlimited instant clones."}
        </p>
        <p style={{ fontFamily: "SSMedium", fontSize: 13, marginTop: -13 }}>
          One credit includes 10mins of video calling.{" "}
          <FaRegCircleQuestion style={{ marginLeft: 5, marginTop: -2 }} />
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          style={{
            width: 280,
            marginLeft: -20,
            height: 60,
            borderRadius: 8,
            marginTop: 10,
            background: "blue",
            paddingTop: subscriptionLoading ? 17 : 0,
            cursor: !active ? "pointer" : null,
            border: "1px solid rgb(11, 11, 207)",
            color: "rgb(9, 9, 162)",
            backgroundColor: "rgb(245, 245, 255)",
          }}
          className="d-flex justify-content-center"
          onClick={() => {
            if (!subscriptionLoading && !active) {
              startSubscription(tier);
            }
          }}
        >
          {subscriptionLoading ? (
            <TailSpin
              height="30"
              width="30"
              color="blue"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ marginTop: -3 }}
              wrapperClass=""
              visible={true}
            />
          ) : active ? (
            <p
              style={{
                marginTop: 16,
                fontFamily: "SSMedium",
                fontSize: 19,
                display: "flex",
                marginLeft: -6,
              }}
            >
              Currently active
            </p>
          ) : (
            <p
              style={{
                marginTop: 16,
                fontFamily: "SSMedium",
                fontSize: 19,
                display: "flex",
                marginLeft: -6,
              }}
            >
              Get started
            </p>
          )}
        </div>
      </MDBCol>
    </MDBRow>
  );
}

export default withRouter(PriceHeading);
