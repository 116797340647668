import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { GiHamburgerMenu } from "react-icons/gi";
import FullScreenMobile from "./fullScreenMobile";
import { IoClose } from "react-icons/io5";
import Logo from "./mimzy-logo.png";

const NavBar = ({ fullScreenMode, disableScroll }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [firebase.auth().currentUser]);

  return (
    <>
      {fullScreenOpen && (
        <FullScreenMobile setFullScreenOpen={setFullScreenOpen} />
      )}
      <MDBRow
        className="d-flex justify-content-center"
        style={{
          width: "105vw",
          zIndex: 970,
          height: 77,
          border: "1px solid transparent",
          position: "sticky",
          backgroundColor: "white",
        }}
      >
        <MDBCol
          style={{
            borderBottom: "0px solid rgb(234, 236, 240)",
            height: 67,
            borderRadius: 0,
            marginTop: 10,
            width: "100vw",
            backgroundColor: "white",
          }}
          className="d-flex justify-content-center"
          size="12"
        >
          <div
            style={{
              width: "100vw",
              paddingTop: 15,
              paddingLeft: 10,
              paddingRight: 20,
            }}
            className="d-flex justify-content-between"
          >
            <NavLink
              style={{
                display: "inline-block",
                cursor: "pointer",
                zIndex: 4000,
              }}
              to="/"
              exact
            >
              <div
                style={{
                  color: "#30312c",
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                  fontFamily: "PPBold",
                  cursor: "pointer",
                  zIndex: 4000,
                }}
              >
                <img
                  style={{
                    height: 24,
                    marginTop: -2.4,
                  }}
                  src={Logo}
                />
              </div>
            </NavLink>

            <div className="navbar-right">
              <a
                target="_blank"
                href="https://calendly.com/mimzy-demo/30min?month=2024-10"
                style={{
                  backgroundColor: "#4f46e5", // Blue background color
                  color: "white", // White text color
                  fontSize: 15,
                  padding: "10px 20px",
                  borderRadius: 50, // Rounded button
                  textDecoration: "none",
                  fontFamily: "SSMedium",
                  border: "2px solid white", // Inner white border
                  boxShadow: "0 0 0 3px #4f46e5", // Outer blue border effect
                }}
              >
                Get started
              </a>
            </div>
            {/* {fullScreenMode ? (
              <IoClose
                onClick={() => {
                  disableScroll.off();
                  setFullScreenOpen(false);
                }}
                style={{ fontSize: 26, zIndex: 2000 }}
              />
            ) : (
              <GiHamburgerMenu
                onClick={() => setFullScreenOpen(true)}
                style={{ fontSize: 26 }}
              />
            )} */}
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(NavBar);
